import React from "react";

function FormErrors(props) {
  if (
    props.formerrors &&
    (props.formerrors.errMessage)
  ) {
    return (
      <div className="signup-error">{props.formerrors.errMessage}</div>
    );
  }
   else {
    return <div />;
  }
}

export default FormErrors;