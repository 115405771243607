import React, { Component } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '../../node_modules/videojs-contrib-eme/dist/videojs-contrib-eme.js';
import '../../node_modules/videojs-http-source-selector/dist/videojs-http-source-selector.js';
import '../../node_modules/videojs-contrib-quality-levels/dist/videojs-contrib-quality-levels.js';
import config from './../config';
import { getVideoThumbnailUrl } from '../models/video';

export default class VideoPlayer extends Component {
  componentDidMount() {
    this.player = videojs(this.props.idkey, {
      fluid: true,
      autoplay: 'muted',
    });

    this.player.httpSourceSelector();
    this.player.ready(this.handlePlayerReady);
  }

  handlePlayerReady = () => {
    if (this.player.eme) {
      this.player.eme.activeSrc = null;
    }
    this.player.src(this.player.currentSources());
    this.player.src({
      src: this.props.media.media_url,
      type: this.props.videotype,
      keySystems: {
        'com.apple.fps.1_0': {
          getCertificate: function (emeOptions, callback) {
            videojs.xhr(
              {
                url: config.drmData.cert,
                method: 'GET',
                responseType: 'arraybuffer',
                headers: {
                  'x-dt-custom-data': btoa(JSON.stringify(config.drmData.data)),
                  Pragma: 'no-cache',
                  'Cache-Control': 'no-cache',
                  'Content-type': 'application/octet-stream',
                },
              },
              (err, response, responseBody) => {
                if (err) {
                  callback(err);
                  return;
                }
                callback(null, new Uint8Array(responseBody));
              }
            );
          },
          getContentId: (emeOptions, initData) => {
            initData = this.removeZeros(initData);
            var contentId = this.arrayToString(initData);
            var query, start, assetId, variantId;
            var PATTERN = 'skd://drmtoday?',
              ASSET_ID = 'assetId',
              VARIANT_ID = 'variantId';
            start = contentId.indexOf(PATTERN);
            if (start > -1) {
              query = contentId.substring(start + PATTERN.length);
              query = query.replace(/assetid/gi, ASSET_ID);
              query = query.replace(/variantid/gi, VARIANT_ID);
              assetId = this.getQueryKey(query, ASSET_ID);
              variantId = this.getQueryKey(query, VARIANT_ID);
              query = PATTERN + ASSET_ID + '=' + assetId;
              console.log('Granttest contentida = ' + query);
              return query;
            } else {
              return '';
            }
          },
          getLicense: (emeOptions, contentId, keyMessage, callback) => {
            console.log('keyMessage :', keyMessage);
            let spcMessage = this.arrToBase64(keyMessage);
            spcMessage = encodeURIComponent(spcMessage);
            let params = 'spc=' + spcMessage;

            videojs.xhr(
              {
                url: config.drmData.license,
                method: 'POST',
                responseType: 'text',
                body: params,
                headers: {
                  'x-dt-custom-data': btoa(JSON.stringify(config.drmData.data)),
                  'Content-type': 'application/x-www-form-urlencoded',
                },
              },
              (err, response, responseBody) => {
                if (err) {
                  callback(err);
                  return;
                }
                var keyText = responseBody.trim();

                if (
                  keyText.substr(0, 5) === '<ckc>' &&
                  keyText.substr(-6) === '</ckc>'
                ) {
                  keyText = keyText.slice(5, -6);
                }

                var key = this.base64DecToArr(keyText);

                callback(null, key);
              }
            );
          },
        },
        'com.widevine.alpha': {
          getLicense: (emeOptions, keyMessage, callback) => {
            console.log('getLicense');
            fetch(config.drmData.widevineUrl, {
              method: 'POST',
              headers: {
                'Content-type': 'application/octet-stream',
                'x-dt-custom-data': btoa(JSON.stringify(config.drmData.data)),
              },
              body: keyMessage,
            })
              .then((response) => {
                if (!response.ok) throw response;
                return response;
              })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                callback(null, this.prepareLicense(data));
              })
              .catch((err) => {
                callback(err);
              });
          },
        },
      },
    });
  };

  prepareLicense = (licenseObj) => {
    const license = {
      license: licenseObj.license,
    };

    try {
      const drmTodayObj = licenseObj;

      if (drmTodayObj && drmTodayObj.status && drmTodayObj.license) {
        if (drmTodayObj.status === 'OK') {
          const str = window.atob(drmTodayObj.license);
          const bufView = new Uint8Array(new ArrayBuffer(str.length));

          for (let i = 0; i < str.length; i++) {
            bufView[i] = str.charCodeAt(i);
          }

          license.license = bufView;
        }
      }
    } catch (e) {
      console.error(e);
    }

    return license.license;
  };

  base64DecToArr = (sBase64, nBlocksSize) => {
    var sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ''),
      nInLen = sB64Enc.length,
      nOutLen = nBlocksSize
        ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize
        : (nInLen * 3 + 1) >> 2,
      taBytes = new Uint8Array(nOutLen);

    for (
      var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0;
      nInIdx < nInLen;
      nInIdx++
    ) {
      nMod4 = nInIdx & 3;
      nUint24 |= this.b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (18 - 6 * nMod4);
      if (nMod4 === 3 || nInLen - nInIdx === 1) {
        for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
          taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
        }
        nUint24 = 0;
      }
    }
    return taBytes;
  };

  getQueryKey = (query, key) => {
    var vars = query.split('&');

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] === key) {
        return pair[1];
      }
    }

    return '';
  };

  arrayToString = (arrData) => {
    var str = String.fromCharCode.apply(null, arrData);
    return str;
  };

  arrToBase64 = (arrData) => {
    var str = this.arrayToString(arrData);
    return btoa(str);
  };

  removeZeros = (arrData) => {
    var newArray = arrData.filter(function (el, idx) {
      return idx % 2 === 0;
    });
    return newArray;
  };

  b64ToUint6 = (nChr) => {
    return nChr > 64 && nChr < 91
      ? nChr - 65
      : nChr > 96 && nChr < 123
      ? nChr - 71
      : nChr > 47 && nChr < 58
      ? nChr + 4
      : nChr === 43
      ? 62
      : nChr === 47
      ? 63
      : 0;
  };

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  togglePlay = () => {
    console.log('TEST');
    if (this.player.paused()) {
      this.player.play();
    } else {
      this.player.pause();
    }
  };

  render() {
    let thumbnail = getVideoThumbnailUrl(this.props.media);

    return (
      <div data-vjs-player style={{ width: '100%' }}>
        <video
          className="video-js"
          id={this.props.idkey}
          controls
          autoPlay
          playsInline
          poster={thumbnail}
          data-setup={JSON.stringify({
            autoplay: true,
            plugins: {
              eme: {},
            },
          })}
        >
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">
              supports HTML5 video
            </a>
          </p>
        </video>
      </div>
    );
  }
}
