import buyer from '../../assests/images/confirmation-graphic.svg';
import React, { MouseEvent } from 'react';

interface PaymentConfirmationModalProps {
  onConfirmation: (event: MouseEvent<HTMLAnchorElement>) => void;
  onCancel: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const PaymentConfirmationModal: React.FC<PaymentConfirmationModalProps> = (
  props
) => {
  return (
    <div
      className="custom-popup confirmation-popup d-none"
      id="pay-for-media-confirmation"
    >
      <div className="popup-content white-radius-box">
        <div className="graphics">
          <img src={buyer} alt="" />
        </div>
        <h3 className="text">Are you sure you want to pay for this content?</h3>
        <div className="d-flex action-btns">
          <a onClick={props.onConfirmation} className="btn btn-outline">
            Yes
          </a>
          <a onClick={props.onCancel} className="btn btn-outline">
            No
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmationModal;
