import React, { Component } from 'react';
import videojs from '../../node_modules/video.js';
import 'video.js/dist/video-js.css';
import '../../node_modules/videojs-contrib-eme/dist/videojs-contrib-eme.js';

export default class VideoPlayer extends Component {
  videoPlayerId = 'add-content-video-player';
  componentDidMount() {
    let player = videojs(this.videoPlayerId);
    player.src({
      src: this.props.media,
      type: this.props.videotype,
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div data-vjs-player style={{ width: '100%' }}>
        <video
          ref={this.props.videoRef}
          className="video-js"
          id={this.videoPlayerId}
          controls
        ></video>
      </div>
    );
  }
}
