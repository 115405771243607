import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import '../assests/css/style.css';
import buyer from '../assests/images/confirmation-graphic.svg';
import stripe from '../assests/images/stripe.png';
import config from './../config';
import GetLoggedInUserDetail from './utility/GetLoggedInUserDetail';
const queryString = require('query-string');
Amplify.configure(config.graphQL);

export default class SellContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  handleStripeAuthorize = async (element) => {
    let cognitoUser = GetLoggedInUserDetail(this.props.auth);

    if (element === 'no') {
      this.props.history.push('/Explore');
    } else {
      const state = Math.random().toString(36).slice(2);
      const parameters = {
        client_id: config.stripe.clientId,
        state: state,
        redirect_uri: config.stripe.publicDomain + 'EditProfile',
        'stripe_user[business_type]': 'individual',
        'stripe_user[business_name]': 'Software',
        'stripe_user[first_name]': cognitoUser.name,
        'stripe_user[last_name]': cognitoUser.name,
        'stripe_user[email]': cognitoUser.email,
        'stripe_user[country]': 'US',
        'suggested_capabilities[]': 'card_payments',
        'stripe_user[dob_day]': '01',
        'stripe_user[dob_month]': '01',
        'stripe_user[dob_year]': '2001',
      };
      window.location.assign(
        config.stripe.authorizeUri + '?' + queryString.stringify(parameters)
      );
    }
  };
  render() {
    return (
      <div className="fullpage-popup confirmation-popup">
        <div className="popup-content white-radius-box">
          <div className="graphics">
            <img src={buyer} alt="" />
          </div>
          <h3 className="text">Are you sure you want to sell your content?</h3>
          <div className="d-flex action-btns">
            <a
              onClick={() => {
                this.handleStripeAuthorize('yes');
              }}
              className="btn btn-outline"
            >
              Yes
            </a>
            <a
              onClick={() => {
                this.handleStripeAuthorize('no');
              }}
              className="btn btn-outline"
            >
              No
            </a>
          </div>
          <p className="stripe-note">
            We use Stripe to make sure you get paid on time and to keep your personal
            bank and details secure. <span>Click Yes</span> to set up your payments
            on Stripe.
            <span className="logo-stripe">
              <img src={stripe} className="img-fluid" alt="" />
            </span>
          </p>
        </div>
      </div>
    );
  }
}
