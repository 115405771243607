function GetLoggedInUserImage(cognitoUser){
  let userImg;
  let userProfile = localStorage.getItem('userProfile');
  if (userProfile){
      userProfile = JSON.parse(userProfile);
      userImg = userProfile.profilePicLocal;
  } else if (cognitoUser['custom:cust_profile_pic']){
      userImg = cognitoUser['custom:cust_profile_pic'];
  } else {
      try {
          userImg = JSON.parse(cognitoUser.picture);
          userImg = userImg.data.url;
      } catch (e) {
          userImg = cognitoUser.picture;
      }
  }
  return userImg;
}

export default GetLoggedInUserImage;