import React, { Component } from 'react';
import FormErrors from "./utility/FormErrors";
import { validateForm } from "./utility/FormValidation";
import { Auth } from 'aws-amplify';
import landingLogo from "../assests/images/logo.svg";
import Footer from './Footer';


class ForgotPasswordVerification extends Component {
  state = {
    verificationcode: "",
    username: "",
    newpassword: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  passwordVerificationHandler = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = validateForm(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      await Auth.forgotPasswordSubmit(
        this.state.username,
        this.state.verificationcode,
        this.state.newpassword
      );
      this.props.history.push("/changepasswordconfirmation");
    }catch(error) {
      console.log(error);
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  addBodyClass = () => {
    document.body.classList.add("bodyStyle");
  }

  render() {
    this.addBodyClass();
    return (
      <section className="section auth">
      
          <div className="login d-flex flex-column flex-grow-1">
            <div className="flex-grow-1 d-flex justify-content-center align-items-sm-center">
              <div className="white-radius-box box d-flex flex-column">
                <a href="/" className="login-logo">
                  <img src={landingLogo} alt="" />
                </a>
                <h5 className="box-heading text-center">
                  SET
                  <br />
                  NEW PASSWORD
                </h5>
          <FormErrors formerrors={this.state.errors} />

          <form onSubmit={this.passwordVerificationHandler}>
            <div className="row login-fields">
            <div className="col-12">
              <p className="control">
                <input
                  type="text"
                  className="form-control"
                  id="verificationcode"
                  aria-describedby="verificationCodeHelp"
                  placeholder="Enter verification code"
                  value={this.state.verificationcode}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            </div>
            <div className="row login-fields">
            <div className="col-12">
              <p className="control has-icons-left">
                <input 
                  className="form-control" 
                  type="username"
                  id="username"
                  aria-describedby="emailHelp"
                  placeholder="Enter username"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            </div>
            <div className="row login-fields">
              <div className="col-12">
              <p className="control has-icons-left">
                <input
                  type="password"
                  className="form-control"
                  id="newpassword"
                  placeholder="New password"
                  value={this.state.newpassword}
                  onChange={this.onInputChange}
                />
              </p>
              </div>
              </div>
              <button className="btn btn-purple w-100">
                  Submit
                </button>

                  <p className="already-member text-center mt-4 mb-0">
                  <a href='/Loginpage' className="text-decoration-none">
                    Back
                  </a>
                  </p>
          </form>
        </div>
      </div>
      <Footer />
    </div>
</section>
    );
  }
}

export default ForgotPasswordVerification;