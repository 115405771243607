import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import '../assests/css/style.css';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Header from './Header';
import ProfileTab from './ProfileTab';
import graphQLFunction from './../graphql/graphQLFunction';
import GetLoggedInUserDetail from './utility/GetLoggedInUserDetail';
import GetLoggedInUserImage from './utility/GetLoggedInUserImage';
import config from './../config';
import buyer from '../assests/images/confirmation-graphic.svg';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    let username;
    if (this.props.auth.user) {
      username = this.props.auth.user.username;
    }

    let cognitoUser = GetLoggedInUserDetail(this.props.auth);
    let profilePic = GetLoggedInUserImage(cognitoUser);
    let name = cognitoUser['custom:cust_display_name'];
    let preferred_username =
      cognitoUser.preferred_username ||
      cognitoUser['cognito:username'] ||
      cognitoUser.Username;
    let description = cognitoUser['custom:description'];
    let website = cognitoUser.website;

    this.state = {
      name: name || cognitoUser.name,
      preferred_username: preferred_username,
      profilePic: profilePic,
      description: description,
      website: website,
      isImageUploaded: false,
      profilePicLocal: null,
      errors: {
        errMessage: null,
      },
      currentUserProfile: false,
      editProfileEnabled: true,
      username: username || cognitoUser['cognito:username'] || cognitoUser.Username,
      email: cognitoUser.email,
      flatSubscriptionAmount: 0,
      flatProductMonth: 1,
      promotionAmount: 0,
      promotionMonth: 0,
      sellerId: null,
      productId: null,
      URLparam: null,
      customerId: null,
      priceId: null,
      userRole: 'owner',
      followers: 0,
      followings: 0,
      stripAccountStatus: false,
      loaded: false,
    };

    const isUserLoggedIn = this.checkIsUserLoggedIn();
    if (!isUserLoggedIn) {
      this.props.history.push('/Loginpage');
    }
  }

  checkIsUserLoggedIn = () => {
    return (
      this.props &&
      this.props.auth &&
      this.props.auth.isAuthenticated &&
      this.props.auth.user
    );
  };

  async componentDidMount() {
    try {
      this.setState({ URLparam: this.props.match.params });
      let params = this.props.match.params;
      if (params.id && params.id === this.state.preferred_username) {
        let parameter = this.state.preferred_username.toLowerCase();
        let result = await graphQLFunction(
          'UsernameSearch',
          parameter,
          'query',
          false
        );
        this.updateState(result, true);
        this.setState({ loaded: true });
      } else if (params.id && params.id !== this.state.preferred_username) {
        const parameter = params.id.toLowerCase();
        const result = await graphQLFunction(
          'UsernameSearch',
          parameter,
          'query',
          false
        );
        this.updateState(result, false);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const payment = urlParams.get('payment');
        const mediaPayment = urlParams.get('mediapayment');
        const customerId = localStorage.getItem('customerId');
        const sessionId = localStorage.getItem('sessionId');
        if (payment === 'true' && customerId && sessionId) {
          let priceId = localStorage.getItem('priceId');
          let productId = localStorage.getItem('productId');
          let parameter = `{object_type:"following",object_id:"${
            this.state.username
          }", customer_id:"${customerId}", pricing_id:"${priceId}", product_id:"${productId}", updated_timestamp:"${parseInt(
            Date.now() / 1000
          )}"}`;
          let followUser = await graphQLFunction(
            'Follow',
            parameter,
            'query',
            false
          );
        } else if (mediaPayment === 'true') {
          if (
            localStorage.getItem('paidMediaObject') &&
            localStorage.getItem('sessionId')
          ) {
            let paidMediaObject = localStorage.getItem('paidMediaObject');
            paidMediaObject = JSON.parse(paidMediaObject);
            let username = this.props.auth.user.username;
            let parameter = `{object_type:"paidmedia#${username}", object_id: "${paidMediaObject.media_id}"}`;
            await graphQLFunction('CreateProduct', parameter, 'query', false);
          }
        }
        localStorage.removeItem('customerId');
        localStorage.removeItem('priceId');
        localStorage.removeItem('productId');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('paidMediaObject');
        localStorage.removeItem('sessionId');
        let followerCountParam = `object_type:"summary#${this.state.username}",object_id:"followerscount"`;
        let followerCountResult = await graphQLFunction(
          'GetFollower',
          followerCountParam,
          'query',
          false
        );
        if (
          followerCountResult.data.getLookupsDev &&
          followerCountResult.data.getLookupsDev.value
        ) {
          this.setState({ followers: followerCountResult.data.getLookupsDev.value });
        }
        let followingCountParam = `object_type:"summary#${this.state.username}",object_id:"followingcount"`;
        let followingCountResult = await graphQLFunction(
          'GetFollower',
          followingCountParam,
          'query',
          false
        );
        if (
          followingCountResult.data.getLookupsDev &&
          followingCountResult.data.getLookupsDev.value
        ) {
          this.setState({
            followings: followingCountResult.data.getLookupsDev.value,
          });
        }
        let parameter2 = `object_type:"following",object_id:"${this.state.username}"`;
        let followingStatus = await graphQLFunction(
          'GetFollowing',
          parameter2,
          'query',
          false
        );
        if (followingStatus.data.getLookupsDev) {
          this.setState({
            customerId: followingStatus.data.getLookupsDev.customer_id,
            priceId: followingStatus.data.getLookupsDev.pricing_id,
            productId: followingStatus.data.getLookupsDev.product_id,
            userRole: 'follower',
          });
        } else {
          this.setState({
            userRole: 'visitor',
          });
        }
        if (this.state.sellerId) {
          try {
            let stripAccountStatus = await axios.get(
              `${config.api.invokeUrl}connect/accounts/${this.state.sellerId}`
            );
            if (stripAccountStatus.status === 200) {
              this.setState({ stripAccountStatus: 'active' });
            }
          } catch (error) {
            console.log('error', error);
            this.setState({ stripAccountStatus: 'inactive' });
          }
        }
      } else {
        this.props.history.push('/Profile');
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      this.setState({ loaded: true });
    }
  }

  updateState = (result, editProfileEnabled) => {
    if (
      result.data.listHwm_users &&
      result.data.listHwm_users.items &&
      this.props.match.params.id
    ) {
      let data = result.data.listHwm_users.items.find(
        (user) => user.username === this.props.match.params.id
      );
      if (!data) return;
      this.setState({
        email: data.email,
        name: data.display_name.substr(1),
        preferred_username: data.username,
        profilePic: `${config.api.s3BucketURL}/${data.user_id}/${data.user_id}.jpg`,
        website: null,
        description: data.caption,
        username: data.user_id,
        editProfileEnabled: editProfileEnabled,
        flatSubscriptionAmount: data.flat_subscription_amount || 0,
        flatProductMonth: Number.isInteger(parseInt(data.flat_product_month))
          ? data.flat_product_month
          : 1 || 1,
        promotionAmount: data.promotion_amount || 0,
        promotionMonth: data.promotion_month || 0,
        sellerId: data.account_id || null,
      });
    } else {
      this.props.history.push('/Pagenotfound');
    }
  };

  subscribeTo = async (element) => {
    this.showLoader();
    const stripe = await loadStripe(config.stripe.testPublishableKey, {
      stripeAccount: this.state.sellerId,
    });
    let amount = this.state.flatSubscriptionAmount;
    let productType = 'product_follow';
    if (element === 'withDiscount') {
      amount = this.state.promotionAmount;
      productType = 'product_follow_promotion';
    }
    let cognitoUser = GetLoggedInUserDetail(this.props.auth);
    let email = cognitoUser.email;
    var customerdata = {
      customer_email: email,
      seller_account_id: this.state.sellerId,
    };
    let customerResult = await axios.post(
      `${config.api.invokeUrl}customers`,
      customerdata
    );
    let customerId = customerResult.data.data.id;
    localStorage.setItem('customerId', customerId);
    let param = `object_type:"${productType}",object_id:"${this.state.username}"`;
    let productData = await graphQLFunction('GetFollowing', param, 'query', false);
    console.log('productData', productData);
    this.setState({
      productId: productData.data.getLookupsDev.product_id,
      priceId: productData.data.getLookupsDev.pricing_id,
    });
    localStorage.setItem('productId', productData.data.getLookupsDev.product_id);
    localStorage.setItem('priceId', productData.data.getLookupsDev.pricing_id);
    var checkoutData = {
      price_id: this.state.priceId,
      success_url: `${config.stripe.publicDomain}Profile/${this.state.URLparam.id}?payment=true`,
      cancel_url: `${config.stripe.publicDomain}Profile/${this.state.URLparam.id}?payment=false`,
      customer_id: customerId,
      seller_account_id: this.state.sellerId,
      amount: amount,
    };
    console.log('checkoutData', checkoutData);
    let subscriptionResult = await axios.post(
      `${config.api.invokeUrl}subscriptions`,
      checkoutData
    );
    let sessionId = subscriptionResult.data.data.id;
    localStorage.setItem('sessionId', sessionId);
    await stripe.redirectToCheckout({
      sessionId,
    });
  };

  unsubscribeTo = async (element) => {
    var data = {
      customer_id: this.state.customerId,
      seller_account_id: this.state.sellerId,
      price_id: this.state.priceId,
    };
    console.table(data);
    axios.delete(`${config.api.invokeUrl}subscriptions`, { data: data });
    let parameter = `{object_type:"following",object_id:"${this.state.username}"}`;
    await graphQLFunction('UnFollow', parameter, 'query', false);
    this.setState({
      userRole: 'visitor',
    });
  };

  paidMediaRequest = async (amount, productId) => {
    this.showLoader();
    const stripePromise = loadStripe(config.stripe.testPublishableKey, {
      stripeAccount: this.state.sellerId,
    });
    const stripe = await stripePromise;
    console.log('stripe', stripe);
    let customerId = this.state.customerId;
    if (!customerId || customerId === 'null') {
      let cognitoUser = GetLoggedInUserDetail(this.props.auth);
      let email = cognitoUser.email;
      var customerdata = {
        customer_email: email,
        seller_account_id: this.state.sellerId,
      };
      let customerResult = await axios.post(
        `${config.api.invokeUrl}customers`,
        customerdata
      );
      customerId = customerResult.data.data.id;
    }
    var checkoutData = {
      product_id: productId,
      success_url: `${config.stripe.publicDomain}Profile/${this.state.URLparam.id}?mediapayment=true`,
      cancel_url: `${config.stripe.publicDomain}Profile/${this.state.URLparam.id}?mediapayment=false`,
      customer_id: customerId,
      seller_account_id: this.state.sellerId,
      amount: amount,
    };
    console.log('checkoutData', checkoutData);
    let subscriptionsResult = await axios.post(
      `${config.api.invokeUrl}media/payments`,
      checkoutData,
      {}
    );
    let sessionId = subscriptionsResult.data.data.id;
    localStorage.setItem('sessionId', sessionId);
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
  };

  showLoader = () => {
    document.getElementsByClassName('loader-overlay')[0].classList.remove('d-none');
  };

  becomeASubscriberConfirmation = () => {
    document
      .getElementById('become-a-subscriber-confirmation')
      .classList.remove('d-none');
  };

  cancelSubscriptionPopup = () => {
    document
      .getElementById('become-a-subscriber-confirmation')
      .classList.add('d-none');
  };

  render() {
    const {
      editProfileEnabled,
      website,
      description,
      username,
      preferred_username,
      name,
      profilePic,
      flatSubscriptionAmount,
      flatProductMonth,
      promotionAmount,
      promotionMonth,
      userRole,
      followers,
      followings,
      stripAccountStatus,
      loaded,
    } = this.state;
    let editProfileAllowed;
    if (editProfileEnabled) {
      editProfileAllowed = (
        <a href="/EditProfile" className="btn btn-outline btn-edit-profile">
          <i className="fas fa-pencil-alt mr-2"></i>
          Edit Profile
        </a>
      );
    }

    let normalSubscription = null;
    if (
      userRole === 'visitor' &&
      flatSubscriptionAmount > 0 &&
      stripAccountStatus !== 'inactive'
    ) {
      let duretion = 'MONTH';
      if (flatProductMonth == 12) {
        duretion = 'YEAR';
      }
      normalSubscription = (
        <button
          className="btn btn btn-purple mb-3 mb-lg-0 mr-0 mr-lg-3"
          onClick={() => {
            this.subscribeTo('withoutDiscount');
          }}
        >
          SUBSCRIBE FOR ${flatSubscriptionAmount}/{duretion}
        </button>
      );
    } else {
      normalSubscription = null;
    }
    let PromotionalSubscription = null;
    if (
      userRole === 'visitor' &&
      promotionAmount > 0 &&
      stripAccountStatus !== 'inactive'
    ) {
      PromotionalSubscription = (
        <button
          className="btn btn btn-outline"
          onClick={() => {
            this.subscribeTo('withDiscount');
          }}
        >
          GET {promotionMonth} MONTHS FOR ${promotionAmount} TOTAL
        </button>
      );
    } else {
      PromotionalSubscription = null;
    }
    let unsubscribe = null;
    if (userRole === 'follower') {
      unsubscribe = (
        <div className="membership-plans mt-4 d-flex flex-column flex-lg-row">
          <button
            className="btn btn btn-purple mb-3 mb-lg-0 mr-0 mr-lg-3"
            onClick={this.unsubscribeTo}
          >
            UNSUBSCRIBE
          </button>
        </div>
      );
    } else {
      unsubscribe = null;
    }

    let websiteElement;
    if (website) {
      websiteElement = (
        <a href={website} className="web-link">
          <i className="fas fa-link"></i> {website}
        </a>
      );
    }
    let followersCount;
    if (userRole !== 'owner') {
      followersCount = (
        <div className="user-subscribed-count">
          <label>Subscribers</label>
          <span>{followers}</span>
        </div>
      );
    }
    let followingCount;
    if (userRole !== 'owner') {
      followingCount = (
        <div className="user-subscribed-count">
          <label>Following</label>
          <span>{followings}</span>
        </div>
      );
    }
    return (
      <div>
        {loaded ? (
          <div className="main-wapper">
            <Sidebar {...this.props} />
            <div className="content-wrapper h-100">
              <Header {...this.props} />
              <div className="profile-section">
                {/* <div className="sec-head mb-3 d-flex align-items-center justify-content-between">
                            <h3 className="section-heading">Profile</h3>
                            <a href="#" className="btn-more-info" data-toggle="modal" data-target="#optionPopup"><i className="fas fa-ellipsis-h"></i></a>
                        </div> */}
                <div className="Profile">
                  <div className="user-info">
                    <div className="user-img">
                      <img src={profilePic} alt="" />
                    </div>
                    <div className="user-details">
                      <h4 className="full-name">{name}</h4>
                      <h6 className="user-name">@{preferred_username}</h6>
                      {editProfileAllowed}
                      <div className="user-subscription-section">
                        {followersCount}
                        {followingCount}
                      </div>
                    </div>
                  </div>
                  <div className="about-user mt-4">
                    <p>{description}</p>
                    {websiteElement}
                  </div>
                  <div className="membership-plans mt-4 d-flex flex-column flex-lg-row">
                    {normalSubscription}
                    {PromotionalSubscription}
                    {unsubscribe}
                  </div>
                  <ProfileTab
                    {...this.props}
                    slug={username}
                    userdetail={this.state}
                    becomeasubscriber={this.becomeASubscriberConfirmation}
                    userrole={userRole}
                    paidmediarequest={this.paidMediaRequest}
                  />
                </div>
                <Footer />
              </div>
            </div>
          </div>
        ) : null}

        <div
          className="modal fade option-popup"
          id="optionPopup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body p-0">
                <ul className="p-0 m-0">
                  <li>
                    <a href="#" className="text-danger">
                      Block
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-danger">
                      Report
                    </a>
                  </li>
                  <li>
                    <a href="#">Copy Profile URL</a>
                  </li>
                  <li>
                    <a href="#">Share This Profile</a>
                  </li>
                  <li>
                    <a href="#">Hide User’s Posts From Feed</a>
                  </li>
                  <li>
                    <a href="#">Unsubscribe</a>
                  </li>
                  <li>
                    <a href="#" data-dismiss="modal">
                      Cancel
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="custom-popup confirmation-popup d-none"
          id="become-a-subscriber-confirmation"
        >
          <div className="popup-content white-radius-box position-relative">
            <a className="btn-close-popup" onClick={this.cancelSubscriptionPopup}>
              <i className="fas fa-times-circle"></i>
            </a>
            <div className="graphics">
              <img src={buyer} alt="" />
            </div>
            <h3 className="text">Follow {name}</h3>
            <div className="d-flex flex-column action-btns">
              <button
                onClick={() => {
                  this.subscribeTo('withoutDiscount');
                }}
                className="btn btn-outline mb-3 w-100"
              >
                SUBSCRIBE FOR ${flatSubscriptionAmount}/MONTH
              </button>
              <button
                onClick={() => {
                  this.subscribeTo('withDiscount');
                }}
                className="btn btn-outline w-100"
              >
                GET {promotionMonth} MONTHS FOR ${promotionAmount} TOTAL
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
