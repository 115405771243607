import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assests/css/style.css";

export default class Pagenotfound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleBackTo = () => {
    this.props.history.push('/Profile');
  };

  render(){
  return (

    <div className="main-wapper">
        <div className="section-404">
            <div className="pt-5 mb-5 text-center">
                <img src={require('../assests/images/404.png')} alt="" className="img-fluid"/>
            </div>
            <h4 className="text-center mb-5">Sorry...This page not found</h4>
            <a className="btn btn-outline" onClick={this.handleBackTo}>
            <i className="fas fa-arrow-left mr-2"></i>
            Back to page</a>
        </div>
    </div>


    )
  }
}
