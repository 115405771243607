import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Landingpage from './components/Landingpage';
import Homefeed from './components/Homefeed';
import Loginpage from './components/Loginpage';
import Signuppage from './components/Signuppage';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordVerification from './components/ForgotPasswordVerification';
import ChangePassword from './components/ChangePassword';
import ChangePasswordConfirm from './components/ChangePasswordConfirm';
import Welcome from './components/Welcome';
import Profile from './components/Profile';
import EditProfile from './components/EditProfile';
import { Auth } from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Explore from './components/Explore';
import WelcomePopup from './components/WelcomePopup';
import Pagenotfound from './components/Pagenotfound';
import Addcontent from './components/Addcontent';
import SellContent from './components/SellContent';
import Mypayouts from './components/Mypayouts';

library.add(faEdit);

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
  };

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  async componentDidMount() {
    try {
      console.log('entered in componentDidMount function');
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      console.log('user', user);
      this.setUser(user);
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      this.setState({ isAuthenticating: false });
    } catch (error) {
      console.log('=======error', error);
      this.setState({ isAuthenticating: false });
    }
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
    };
    return (
      !this.state.isAuthenticating && (
        <div className="App w-100">
          <div className="loader-overlay d-none">
            <div className="cssload-loader">
              <div className="cssload-side"></div>
              <div className="cssload-side"></div>
              <div className="cssload-side"></div>
              <div className="cssload-side"></div>
              <div className="cssload-side"></div>
              <div className="cssload-side"></div>
              <div className="cssload-side"></div>
              <div className="cssload-side"></div>
            </div>
          </div>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Landingpage {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/Homefeed"
                render={(props) => <Homefeed {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/Loginpage"
                render={(props) => <Loginpage {...props} auth={authProps} />}
              />
              <Route
                path="/Signuppage"
                render={(props) => <Signuppage {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/forgotpassword"
                render={(props) => <ForgotPassword {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/forgotpasswordverification"
                render={(props) => (
                  <ForgotPasswordVerification {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/changepassword"
                render={(props) => <ChangePassword {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/changepasswordconfirmation"
                render={(props) => (
                  <ChangePasswordConfirm {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/Welcome"
                render={(props) => <Welcome {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/SellContent"
                render={(props) => <SellContent {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/Explore"
                render={(props) => <Explore {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/Profile"
                render={(props) => <Profile {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/Profile/:id"
                render={(props) => <Profile {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/Editprofile"
                render={(props) => <EditProfile {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/Addcontent"
                render={(props) => <Addcontent {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/Mypayouts"
                render={(props) => <Mypayouts {...props} auth={authProps} />}
              />
              <Route exact path="/WelcomePopup" component={WelcomePopup} />
              <Route
                exact
                path="/*"
                render={(props) => <Pagenotfound {...props} auth={authProps} />}
              />
            </Switch>
          </Router>
        </div>
      )
    );
  }
}

export default App;
