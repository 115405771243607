import Dropdown from 'react-bootstrap/Dropdown';
import React, { MouseEvent } from 'react';
import Button from 'react-bootstrap/Button';
import IMedia from '../../../models/IMedia';

interface MediaOptionsDropdownProps {
  media: IMedia;
  onDelete: (mediaId: string) => void;
}

const MediaOptionsDropdown: React.FC<MediaOptionsDropdownProps> = ({
  onDelete,
  media,
}) => {
  return (
    <Dropdown>
      {/* Typescript complains because of the 'as' prop
       // @ts-ignore */}
      <Dropdown.Toggle id="media-dropdown-toggle" as={CustomToggle} />
      <Dropdown.Menu id="media-dropdown-menu">
        <Dropdown.Item onClick={() => onDelete(media.media_id)}>
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

interface CustomToggleProps {
  onClick: (event: MouseEvent) => void;
}

const CustomToggle = React.forwardRef<any, CustomToggleProps>(({ onClick }, ref) => (
  <Button
    onClick={onClick}
    ref={ref}
    style={{ backgroundColor: 'rgba(0,0,0,0.0)', color: 'white', borderWidth: '0' }}
  >
    <i className="fas fa-ellipsis-v" />
  </Button>
));

export default MediaOptionsDropdown;
