import React, { Component } from "react";
import Amplify, { Auth } from "aws-amplify";
import Footer from "./Footer.js";
import Header from "./Header.js";
import Sidebar from "./Sidebar";
import axios from 'axios';
import GetLoggedInUserImage from "./utility/GetLoggedInUserImage";
import "../assests/css/style.css";
import stripe from "../assests/images/stripe.png";
import config from "./../config";
import graphQLFunction from "./../graphql/graphQLFunction";
import GetLoggedInUserDetail from "./utility/GetLoggedInUserDetail";
const queryString = require('query-string');
Amplify.configure(config.graphQL)


export default class Mypayouts extends Component {
    constructor(props) {
        super(props);
        const isUserLoggedIn = this.checkIsUserLoggedIn();
        if (!isUserLoggedIn) {
            this.props.history.push("/Loginpage");
        }
        let username;
        if (this.props.auth.user) {
            username = this.props.auth.user.username;
        }
        let cognitoUser = GetLoggedInUserDetail(this.props.auth);
        let profilePic = GetLoggedInUserImage(cognitoUser);
        let name = cognitoUser['custom:cust_display_name'];

        let preferred_username = cognitoUser.preferred_username || cognitoUser['cognito:username'] || cognitoUser.Username;
        this.state = {
            username: username,
            profilePic: profilePic,
            name: name || cognitoUser.name,
            preferred_username: preferred_username,
            subscriberCount: 0,
            followingCount: 0,
            sellerId: null,
            availableBal: 0.0,
            availableBaltoShow: 0.0,
            pendingBal: 0.0,
            instantBal: 0.0,
            yourBal: 0.0,
            recentPayments: [],
            total_amount: 0,
            salesCount: 0,
            disabled: true
        };
    }

    async componentDidMount() {
        try {
            let cognitoUser = GetLoggedInUserDetail(this.props.auth);
            let param = cognitoUser.preferred_username.toLowerCase();
            let result = await graphQLFunction('UsernameSearch', param, "query", false);
            this.updateState(result, true);
        }
        catch (error) {
            console.log('error in updateState', error);
        }

        this.getAccDetails();
        this.getAccBalance();
        this.getAccList();
        this.getPaymentList();
        this.getPaymentList();

    }

    showLoader = () => {
        document
            .getElementsByClassName("loader-overlay")[0]
            .classList.remove("d-none");
    };

    hideLoader = () => {
        document
            .getElementsByClassName("loader-overlay")[0]
            .classList.add("d-none");
    };

    showButton = () => {
        
        document.getElementById("payoutNow").classList.remove("disabled");
      };
    
      hideButton = () => {
        document.getElementById("payoutNow").classList.add("disabled");
      };

    getAccDetails = async event => {
        let accId = this.state.sellerId;
        await axios.get(`${config.api.invokeUrl}connect/accounts/${accId}`)
            .then(async res => {
            }).catch(error => {
                return error;
            });
    }

    validatePayout = () =>{
        if (this.state.availableBal > 0) {
        this.setState({ disabled: false });
        this.showButton();
        } else {
        this.setState({ disabled: true });
        this.hideButton();
        }
    }


    getAccBalance = async event => {
        let accId = this.state.sellerId;
        await axios.get(`${config.api.invokeUrl}connect/balance/${accId}`)
            .then(async res => {
                let newAvailableBal = (res.data.data.available[0].amount);
                this.setState({ availableBal: newAvailableBal.toFixed(2) });
                let newAvailableBaltoShow = (res.data.data.available[0].amount/100);
                this.setState({availableBaltoShow: newAvailableBaltoShow.toFixed(2)});
                let newYourBal = (res.data.data.available[0].amount / 100) + (res.data.data.pending[0].amount / 100);
                this.setState({ yourBal: newYourBal.toFixed(2) });
                this.validatePayout();
            }).catch(error => {
                return error;
            });
    }

    getPaymentList = async event => {
        this.showLoader();
        let accId = this.state.sellerId;
        await axios.get(`${config.api.invokeUrl}connect/payments/${accId}`)
            .then(async res => {
                this.hideLoader();
                let payments = res.data.data;
                let totalAmount = res.data.total_amount;
                this.setState({ recentPayments: payments })
                this.setState({ total_amount: totalAmount })
                let count = this.state.recentPayments.length;
                this.setState({ salesCount: count });
            }).catch(error => {
                return error;
            });
    }

    getAccList = async event => {
        await axios.get(`${config.api.invokeUrl}connect/accounts`)
            .then(async res => {
            }).catch(error => {
                return error;
            });
    }

    updateState = (result, editProfileEnabled) => {
        if (result.data.listHwm_users && result.data.listHwm_users.items && result.data.listHwm_users.items[0]) {
            let data = result.data.listHwm_users.items[0];
            this.setState({
                sellerId: data.account_id
            })
        }
    }

    checkIsUserLoggedIn = () => {
        return (
            this.props &&
            this.props.auth &&
            this.props.auth.isAuthenticated &&
            this.props.auth.user
        );
    };

    payoutNow = async event => {
        event.preventDefault();

        if(this.state.availableBal > 0){
            var data = {
                "seller_account_id": this.state.sellerId,
                "amount": this.state.availableBal
            }
        await axios.post(`${config.api.invokeUrl}connect/accounts/payouts`, data, {
        }).then(async res => {
            this.getAccBalance();
            // this.getPaymentList();
        }).catch(error => {
            return error;
        });
    }
    }

    viewStripePayout = async event => {
        event.preventDefault();

        var data = {
            "seller_account_id": this.state.sellerId
        }

        await axios.post(`${config.api.invokeUrl}connect/login`, data, {
        }).then(async res => {
            window.open(res.data.data.url + '#/', "_blank");
        }).catch(error => {
            return error;
        });

    }

    viewStripeAccount = async event => {
        event.preventDefault();

        var data = {
            "seller_account_id": this.state.sellerId
        }

        await axios.post(`${config.api.invokeUrl}connect/login`, data, {
        }).then(async res => {
            window.open(res.data.data.url + '#/account', "_blank");
        }).catch(error => {
            return error;
        });

    }

    imageExists = (imgPath) => {

        var http = new XMLHttpRequest();

        http.open('HEAD', imgPath, true);
        http.send();

        return http.status != 404;

    }

    loadImage = function (imgPath) {
        if (this.imageExists(imgPath)) {
            return imgPath;
        }
        else {
            return `${config.api.s3BucketURL}/unknown.jpg`;
        }
    }


    render() {
        const {
            profilePic,
        } = this.state;

        const data = this.state.recentPayments;
        const list = data.map((elements, i) => {
            elements.img = this.loadImage(`${config.api.s3BucketURL}/${elements.username}/${elements.username}.jpg`);
            return (
                <tr key={i}>
                    <td>
                        <div className="recent-payment-user d-flex">
                            <span className="recent-payment-user-img">
                                <img src={elements.img} alt="" />
                            </span>
                            <span className="recent-payment-username">@{elements.user_id}</span>
                        </div>
                    </td>
                    <td>
                        <div className="purchased-details">
                            {/* <p className="mb-0">Purchased Monthly</p> */}
                            <p className="mb-0">{elements.description}</p>
                            {/* <p className="mb-0">Subscription - <span>5/8 3:45 PM</span></p> */}
                        </div>
                    </td>
                    <td>
                        <div className="purchased-amount text-right">${elements.amount}</div>
                    </td>
                </tr>

            )

        })

        return (
            <div>
                <div className="main-wapper">
                    <Sidebar {...this.props} />
                    <div className="content-wrapper h-100">
                        <Header {...this.props} />

                        <div className="feed-section">
                            <div className="sec-head mb-3 d-flex align-items-center justify-content-between">
                                <h3 className="section-heading">My Payouts</h3>
                            </div>



                            <div className="my-payouts">
                                <div className="payout-info">
                                    <div className="seller-box">
                                        <div className="seller-info d-flex">
                                            <div className="seller-img rounded-circle">
                                                <img src={profilePic} className="img-fluid" alt="" />
                                            </div>
                                            <div className="seller-details">
                                                <div className="member-since">Seller since May 2020</div>
                                                <div className="seller-name">{this.state.name}</div>
                                                <a href="#" className="link text-orange d-inline-flex mt-2" onClick={this.viewStripeAccount}>View Stripe Account</a>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="payout-details">
                                        <div className="payout-details-box">
                                            <div className="payout-box">
                                                <div>This week</div>
                                                <div className="balance-amount">${this.state.total_amount}</div>
                                                <div className="sale-count">{this.state.salesCount} sales</div>
                                            </div>
                                        </div>
                                        <div className="payout-details-box">
                                            <div className="payout-box">
                                                <div>Your balance</div>
                                                <div className="balance-amount">${this.state.yourBal}</div>
                                                <div className="sale-count">${this.state.availableBaltoShow} available</div>
                                            </div>
                                        </div>
                                        <div className="payout-details-box">
            
                                            <button
                                            disabled={this.state.disabled}
                                            id="payoutNow"
                                             className="btn btn-payout disabled" 
                                             onClick={this.payoutNow}
                                             >Pay out now</button>
                                            <a href="#" className="link text-orange d-inline-flex mt-2 text-nowrap" onClick={this.viewStripePayout}>View payouts on Stripe</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="component-heading mt-5 pt-0 pt-md-4">Recent Payments</div>
                                <div className="custom-table-grid payout-tbl responsive-table mt-4">

                                    <table className="table  mb-0">
                                        <tbody>
                                            {list}
                                        </tbody>
                                    </table>
                                </div>

                            </div>


                            <Footer />

                        </div>
                    </div>
                </div>

            </div>

        );
    }
}
