import React, { Component } from 'react';
import {validateSignUp} from "./utility/FormValidation";
import FormErrors from "./FormErrors";
import { Auth } from "aws-amplify";
import "../assests/css/style.css"
import landingLogo from "../assests/images/logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from './Footer';

class Signuppage extends Component {
  state = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    terms: false,
    disabled: true,
    errors: {
      errMessage: null
    }
  }

  showLoader = () => {
    document.getElementsByClassName("loader-overlay")[0].classList.remove("d-none");
  }

  hideLoader = () => {
    document.getElementsByClassName("loader-overlay")[0].classList.add("d-none");
  }

  showButton = () => {
    document.getElementById("signUpBtn").classList.remove("disabled");
    document.getElementById("signUpBtn").classList.add("btn-purple");

  }

  hideButton = () => {
    document.getElementById("signUpBtn").classList.add("disabled");
    document.getElementById("signUpBtn").classList.remove("btn-purple");

  }

  clearErrorState = () => {
    this.setState({
      errors: {
        errMessage: null
      }
    });
  }

  handleGoogleSignUp = async event => {
    try {
      this.setState({ isAuthenticating: true });
      event.preventDefault();
      await Auth.federatedSignIn({provider: 'Google'})
      this.props.history.push("/Welcome");

    } catch (error) {
      this.setState({
        errors: {
          errMessage: error.message
        }
      });
    }
  }

  handleFacebookSignUp = async event => {
    try {
      this.setState({ isAuthenticating: true });
      event.preventDefault();
      await Auth.federatedSignIn({provider: 'Facebook'})

      this.props.history.push("/Welcome");

    } catch (error) {
      this.setState({
        errors: {
          errMessage: error.message
        }
      });
    }
  }

  addBodyClass = () => {
    document.body.classList.add("bodyStyle");
  }

  callAwsSignup = async() =>{
    const { firstName, userName, lastName, email, password } = this.state;
    const username = userName
    try {
      await Auth.signUp({username, password, attributes: {
          email: email,
          name: firstName + " " + lastName
        }
      });
      this.hideLoader();
      this.props.history.push("/WelcomePopup");
    } catch (error) {
      this.hideLoader();
      this.setState({
        errors: {
          errMessage: error.message
        }
      });
    }
  }
 

  handleSubmit = async event => {
    event.preventDefault();
    this.clearErrorState();
    const error = validateSignUp();
    if(error === "") {
      this.showLoader();
      await this.callAwsSignup();
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  handleCheck = event =>{
    this.setState({terms: event.target.checked})
    const error = validateSignUp();

    if(event.target.checked && error === ""){
      this.setState({disabled: false});
      this.showButton();
    }
    else{
      this.setState({disabled: true});
      this.hideButton();
    }
  }

  render() {
    this.addBodyClass();

    return (
      <div className="wrapper section auth">
        <div className="login d-flex flex-column flex-grow-1">
        <div className="flex-grow-1 d-flex justify-content-center align-items-sm-center">
        <div className="white-radius-box box">
                <a href="/" className="login-logo">
                  <img src={landingLogo} alt="" />
                </a>
                <h5 className="box-heading text-center">Sign up</h5>

          <div className="d-flex align-items-center justify-content-between social-login">
                  <button className="btn btn-outline btn-facebook" onClick={this.handleFacebookSignUp}>

                    <i className="fab fa-facebook-f fa-lg mr-2">
                    </i>Continue
                  </button>
                  <button className="btn btn-outline btn-google" onClick={this.handleGoogleSignUp}>
                    <i className="fab fa-google fa-lg mr-2"></i>Continue
                  </button>
                </div>
                <div className="or">
                  <span>OR</span>
                  </div>
                  <FormErrors formerrors={this.state.errors} />
                  <form className="w-100"
                  onSubmit={this.handleSubmit}>
                  <div className="row login-fields">
                    <div className="col-6">
                      <div className="form-group position-relative">
                        <input
                        required
                          type="text"
                          id="firstName"
                          className="form-control required"
                          placeholder="First Name"
                          value={this.state.firstName}
                          onChange={this.onInputChange}
                        />
                        <div className="errMessage text-left"></div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group position-relative">
                        <input
                        required
                          type="text"
                          id="lastName"
                          className="form-control required"
                          placeholder="Last Name"
                          value={this.state.lastName}
                          onChange={this.onInputChange}
                        />
                        <div className="errMessage text-left"></div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group position-relative">
                        <input
                        required
                          type="text"
                          id="userName"
                          className="form-control required"
                          placeholder="User Name"
                          value={this.state.userName}
                          onChange={this.onInputChange}
                        />
                        <div className="errMessage text-left"></div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group position-relative">
                        <input
                        required
                          type="email"
                          id="email"
                          className="form-control required"
                          placeholder="Email Address"
                          value={this.state.email}
                          onChange={this.onInputChange}
                        />
                        <div className="errMessage text-left"></div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group position-relative">
                        <input
                        required
                          type="password"
                          id="password"
                          className="form-control required"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.onInputChange}
                        />
                        <div className="errMessage text-left"></div>
                      </div>
                    </div>
                   
                    <div className="col-12">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                          required
                            type="checkbox"
                            className="custom-control-input required"
                            id="customCheck1"
                            checked={this.state.terms}
                            onChange={this.handleCheck}
                          />
                          <label
                            className="custom-control-label"
                            for="customCheck1"
                          >
                            By signing up you agree to our Terms of Service and
                            Privacy Policy.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                  <button disabled={this.state.disabled} id="signUpBtn" className="btn disabled w-100" onClick ={this.handleSubmit}>
                    SIGN UP
                  </button>
                </div>
                </form>
                
                <p className="already-member text-center mt-4 mb-0">
                  Already a member?
                  <a href="Loginpage" className="ml-1">
                    Log In
                  </a>
                </p>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      
    );
  }
}

export default Signuppage;
