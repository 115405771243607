import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import graphQLFunction from '../graphql/graphQLFunction';
import debounce from 'lodash/debounce';
import noPhotoImage from './../assests/images/no-photo.png';
import noVideoImage from './../assests/images/no-video.png';
import blurImg from './../assests/images/blur-image.jpg';
import GetLoggedInUserDetail from './utility/GetLoggedInUserDetail';
import GetLoggedInUserImage from './utility/GetLoggedInUserImage';
import { getVideoThumbnailUrl } from '../models/video';
import MediaModal from './modals/MediaModal/MediaModal';
import PaymentConfirmationModal from './modals/PaymentConfirmationModal';
import MediaManagement from '../services/mediaManagement';

export const MimetypesKind = {
  opus: 'video/ogg',
  ogv: 'video/ogg',
  mp4: 'video/mp4',
  mov: 'video/mp4',
  m4v: 'video/mp4',
  mkv: 'video/x-matroska',
  m4a: 'audio/mp4',
  mp3: 'audio/mpeg',
  aac: 'audio/aac',
  oga: 'audio/ogg',
  m3u8: 'application/x-mpegURL',
  webp: 'image/webp',
  mpd: 'application/dash+xml',
};

export default class ProfileTab extends Component {
  constructor(props) {
    super(props);
    let cognitoUser = GetLoggedInUserDetail(this.props.auth);
    let profilePic = GetLoggedInUserImage(cognitoUser);
    this.state = {
      name: cognitoUser.name,
      preferred_username: cognitoUser.preferred_username,
      profilePic: profilePic,
      error: false,
      hasMore: true,
      isLoading: false,
      tabWidth: 0,
      isSafariBrowser: false,
      videoExt: 'mpd',
      videoType: 'application/dash+xml',
      folderType: 'DASH',
      posts: [],
      videos: [],
      photos: [],
      popupData: null,
      nextTokenForListMedia: null,
    };

    window.onscroll = debounce(() => {
      const {
        loadUsers,
        state: { error, isLoading, hasMore, nextTokenForListMedia },
      } = this;

      if (error || isLoading || !hasMore || nextTokenForListMedia === null) return;
      loadUsers();
    }, 100);
  }

  componentDidMount() {
    if (
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1
    ) {
      this.setState({
        isSafariBrowser: true,
        videoExt: 'm3u8',
        videoType: 'application/x-mpegURL',
        folderType: 'HLS',
      });
    } else {
      this.setState({
        isSafariBrowser: false,
        videoExt: 'mpd',
        videoType: 'application/dash+xml',
        folderType: 'DASH',
      });
    }
    let width = document.getElementById('profile-tabs').offsetWidth;
    this.setState({ tabWidth: width });
    this.loadUsers();
  }

  isPublished = (element) => {
    return element.media_state === '1';
  };

  loadUsers = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        if (this.props.userrole === 'owner') {
          this.ownerMediaList();
        }
        if (this.props.userrole === 'follower') {
          this.followerMediaList();
        }
        if (this.props.userrole === 'visitor') {
          this.visitorMediaList();
        }
      }
    );
  };

  setSecurityAttribute(e) {
    if (e.security_attribute === '2') {
      return (e.security_attribute = 'subscribed');
    } else if (e.security_attribute === '3') {
      return (e.security_attribute = 'paid');
    } else {
      return (e.security_attribute = 'public');
    }
  }

  ownerMediaList = async () => {
    let securityAttr = 1;
    let parameter = `{user_id: {eq: "${this.props.slug}"},security_attribute: {eq: "${securityAttr}"}}`;
    const result = await graphQLFunction(
      'ListMedia',
      parameter,
      'query',
      this.state.nextTokenForListMedia
    );
    if (
      result &&
      result.data.listHwm_medias &&
      result.data.listHwm_medias.items &&
      result.data.listHwm_medias.items.length > 0
    ) {
      let postsList = this.state.posts;
      let photosList = this.state.photos;
      let videosList = this.state.videos;
      let res = result.data.listHwm_medias.items;
      res.forEach((d) => {
        d.security_attribute = this.setSecurityAttribute(d);
        let getSignedURLFuntion = 'GetSignedUrlImage';
        let parameter = {
          user_id: d.user_id,
          media_id: d.media_id,
          media_title: d.media_path,
        };
        if (d.media_type === '2') {
          getSignedURLFuntion = 'GetSignedUrlVideo';
          let ext = this.state.videoExt;
          let folderType = this.state.folderType;
          let splitMediaName = d.media_path.split('.');
          let mediaNameWithOutExt = splitMediaName[0];
          let mediaName = `${mediaNameWithOutExt}.${ext}`;
          let path = `inputs/${this.props.slug}/${d.media_id}/${mediaNameWithOutExt}/${folderType}/${mediaName}`;
          parameter = { user_id: d.user_id, path: `${path}` };
        }
        graphQLFunction(getSignedURLFuntion, parameter, 'query', false).then(
          (result) => {
            let type = 'getSignedUrlImage';
            if (d.media_type === '2') type = 'getSignedUrlVideo';
            if (
              result &&
              result.data &&
              result.data[type] &&
              result.data[type].body
            ) {
              let data = JSON.parse(result.data[type].body);
              d.media_url = data.message;
              if (d.media_type === '0') postsList.push(d);
              if (d.media_type === '1') photosList.push(d);
              if (d.media_type === '2') videosList.push(d);
              this.setState({ isLoading: false });
            }
          }
        );
      });
      this.setState({
        posts: postsList,
        photos: photosList,
        videos: videosList,
      });
      if (result.data.listHwm_medias.nextToken) {
        this.setState({
          nextTokenForListMedia: result.data.listHwm_medias.nextToken,
        });
      } else {
        this.setState({
          nextTokenForListMedia: null,
          hasMore: false,
        });
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  followerMediaList() {
    let securityAttr = 2;
    let parameter = `{user_id: {eq: "${this.props.slug}"},security_attribute: {eq: "${securityAttr}"}}`;
    let self = this;
    graphQLFunction(
      'ListMedia',
      parameter,
      'query',
      this.state.nextTokenForListMedia
    )
      .then((result) => {
        if (
          result &&
          result.data.listHwm_medias &&
          result.data.listHwm_medias.items &&
          result.data.listHwm_medias.items.length > 0
        ) {
          let postsList = this.state.posts;
          let photosList = this.state.photos;
          let videosList = this.state.videos;
          let response = result.data.listHwm_medias.items;
          var res = response.filter(this.isPublished);
          res.forEach((mediaItem) => {
            mediaItem.security_attribute = self.setSecurityAttribute(mediaItem);
            if (
              mediaItem.security_attribute === 'public' ||
              mediaItem.security_attribute === 'subscribed'
            ) {
              let getSignedURLFuntion = 'GetSignedUrlImage';
              let parameter = {
                user_id: mediaItem.user_id,
                media_id: mediaItem.media_id,
                media_title: mediaItem.media_path,
              };
              if (mediaItem.media_type === '2') {
                getSignedURLFuntion = 'GetSignedUrlVideo';
                let ext = self.state.videoExt;
                let folderType = self.state.folderType;
                let splitMediaName = mediaItem.media_path.split('.');
                let mediaNameWithOutExt = splitMediaName[0];
                let mediaName = `${mediaNameWithOutExt}.${ext}`;
                let path = `inputs/${self.props.slug}/${mediaItem.media_id}/${mediaNameWithOutExt}/${folderType}/${mediaName}`;
                parameter = { user_id: mediaItem.user_id, path: `${path}` };
              }
              graphQLFunction(getSignedURLFuntion, parameter, 'query', false).then(
                (result) => {
                  let type = 'getSignedUrlImage';
                  if (mediaItem.media_type === '2') type = 'getSignedUrlVideo';
                  if (
                    result &&
                    result.data &&
                    result.data[type] &&
                    result.data[type].body
                  ) {
                    let data = JSON.parse(result.data[type].body);
                    mediaItem.media_url = data.message;
                    if (mediaItem.media_type === '0') postsList.push(mediaItem);
                    if (mediaItem.media_type === '1') photosList.push(mediaItem);
                    if (mediaItem.media_type === '2') videosList.push(mediaItem);
                    self.setState({ isLoading: false });
                  }
                }
              );
            } else {
              let parameter = `object_type:"paidmedia", object_id: "${mediaItem.media_id}"`;
              graphQLFunction('GetFollowing', parameter, 'query', false).then(
                (result) => {
                  if (
                    result &&
                    result.data &&
                    result.data.getLookupsDev &&
                    result.data.getLookupsDev.object_id
                  ) {
                    let getSignedURLFuntion = 'GetSignedUrlImage';
                    let parameter = {
                      user_id: mediaItem.user_id,
                      media_id: mediaItem.media_id,
                      media_title: mediaItem.media_path,
                    };
                    if (mediaItem.media_type === '2') {
                      getSignedURLFuntion = 'GetSignedUrlVideo';
                      let ext = self.state.videoExt;
                      let folderType = self.state.folderType;
                      let splitMediaName = mediaItem.media_path.split('.');
                      let mediaNameWithOutExt = splitMediaName[0];
                      let mediaName = `${mediaNameWithOutExt}.${ext}`;
                      let path = `inputs/${self.props.slug}/${mediaItem.media_id}/${mediaNameWithOutExt}/${folderType}/${mediaName}`;
                      parameter = { user_id: mediaItem.user_id, path: `${path}` };
                    }
                    graphQLFunction(
                      getSignedURLFuntion,
                      parameter,
                      'query',
                      false
                    ).then((result) => {
                      let type = 'getSignedUrlImage';
                      if (mediaItem.media_type === '2') type = 'getSignedUrlVideo';
                      if (
                        result &&
                        result.data &&
                        result.data[type] &&
                        result.data[type].body
                      ) {
                        let data = JSON.parse(result.data[type].body);
                        mediaItem.media_url = data.message;
                        mediaItem.security_attribute = 'public';
                        if (mediaItem.media_type === '0') postsList.push(mediaItem);
                        if (mediaItem.media_type === '1') photosList.push(mediaItem);
                        if (mediaItem.media_type === '2') videosList.push(mediaItem);
                        self.setState({ isLoading: false });
                      }
                    });
                  } else {
                    if (mediaItem.media_type === '0') postsList.push(mediaItem);
                    if (mediaItem.media_type === '1') photosList.push(mediaItem);
                    if (mediaItem.media_type === '2') videosList.push(mediaItem);
                    self.setState({ isLoading: false });
                  }
                }
              );
            }
          });
          this.setState({
            posts: postsList,
            photos: photosList,
            videos: videosList,
          });
          if (result.data.listHwm_medias.nextToken) {
            this.setState({
              nextTokenForListMedia: result.data.listHwm_medias.nextToken,
            });
          } else {
            this.setState({
              nextTokenForListMedia: null,
              hasMore: false,
            });
          }
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
          isLoading: false,
        });
      });
  }

  visitorMediaList = async () => {
    let securityAttr = 1;
    let parameter = `{user_id: {eq: "${this.props.slug}"},security_attribute: {eq: "${securityAttr}"}}`;
    let self = this;
    const result = await graphQLFunction(
      'ListMedia',
      parameter,
      'query',
      this.state.nextTokenForListMedia
    );
    if (
      result &&
      result.data.listHwm_medias &&
      result.data.listHwm_medias.items &&
      result.data.listHwm_medias.items.length > 0
    ) {
      let postsList = this.state.posts;
      let photosList = this.state.photos;
      let videosList = this.state.videos;
      let response = result.data.listHwm_medias.items;
      var res = response.filter(this.isPublished);
      res.forEach((d, i) => {
        d.security_attribute = self.setSecurityAttribute(d);
        if (d.security_attribute === 'public') {
          let getSignedURLFuntion = 'GetSignedUrlImage';
          let parameter = {
            user_id: d.user_id,
            media_id: d.media_id,
            media_title: d.media_path,
          };
          if (d.media_type === '2') {
            getSignedURLFuntion = 'GetSignedUrlVideo';
            let ext = self.state.videoExt;
            let folderType = self.state.folderType;
            let splitMediaName = d.media_path.split('.');
            let mediaNameWithOutExt = splitMediaName[0];
            let mediaName = `${mediaNameWithOutExt}.${ext}`;
            let path = `inputs/${self.props.slug}/${d.media_id}/${mediaNameWithOutExt}/${folderType}/${mediaName}`;
            parameter = { user_id: d.user_id, path: `${path}` };
          }
          graphQLFunction(getSignedURLFuntion, parameter, 'query', false).then(
            (result) => {
              let type = 'getSignedUrlImage';
              if (d.media_type === '2') type = 'getSignedUrlVideo';
              if (
                result &&
                result.data &&
                result.data[type] &&
                result.data[type].body
              ) {
                let data = JSON.parse(result.data[type].body);
                d.media_url = data.message;
                if (d.media_type === '0') postsList.push(d);
                if (d.media_type === '1') photosList.push(d);
                if (d.media_type === '2') videosList.push(d);
                self.setState({ isLoading: false });
              }
            }
          );
        } else if (d.security_attribute === 'paid') {
          let parameter = `object_type:"paidmedia", object_id: "${d.media_id}"`;
          graphQLFunction('GetFollowing', parameter, 'query', false).then(
            (result) => {
              if (
                result &&
                result.data &&
                result.data.getLookupsDev &&
                result.data.getLookupsDev.object_id
              ) {
                let getSignedURLFuntion = 'GetSignedUrlImage';
                let parameter = {
                  user_id: d.user_id,
                  media_id: d.media_id,
                  media_title: d.media_path,
                };
                if (d.media_type === '2') {
                  getSignedURLFuntion = 'GetSignedUrlVideo';
                  let ext = self.state.videoExt;
                  let folderType = self.state.folderType;
                  let splitMediaName = d.media_path.split('.');
                  let mediaNameWithOutExt = splitMediaName[0];
                  let mediaName = `${mediaNameWithOutExt}.${ext}`;
                  let path = `inputs/${self.props.slug}/${d.media_id}/${mediaNameWithOutExt}/${folderType}/${mediaName}`;
                  parameter = { user_id: d.user_id, path: `${path}` };
                }
                graphQLFunction(getSignedURLFuntion, parameter, 'query', false).then(
                  (result) => {
                    let type = 'getSignedUrlImage';
                    if (d.media_type === '2') type = 'getSignedUrlVideo';
                    if (
                      result &&
                      result.data &&
                      result.data[type] &&
                      result.data[type].body
                    ) {
                      let data = JSON.parse(result.data[type].body);
                      d.media_url = data.message;
                      d.security_attribute = 'public';
                      if (d.media_type === '0') postsList.push(d);
                      if (d.media_type === '1') photosList.push(d);
                      if (d.media_type === '2') videosList.push(d);
                      self.setState({ isLoading: false });
                    }
                  }
                );
              } else {
                if (d.media_type === '0') postsList.push(d);
                if (d.media_type === '1') photosList.push(d);
                if (d.media_type === '2') videosList.push(d);
                self.setState({ isLoading: false });
              }
            }
          );
        } else {
          if (d.media_type === '0') postsList.push(d);
          if (d.media_type === '1') photosList.push(d);
          if (d.media_type === '2') videosList.push(d);
          self.setState({ isLoading: false });
        }
      });
      this.setState({
        posts: postsList,
        photos: photosList,
        videos: videosList,
      });
      if (result.data.listHwm_medias.nextToken) {
        this.setState({
          nextTokenForListMedia: result.data.listHwm_medias.nextToken,
        });
      } else {
        this.setState({
          nextTokenForListMedia: null,
          hasMore: false,
        });
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  subscriptionRequest = () => {
    this.props.becomeasubscriber();
  };

  payConfirmation = (mediaObj) => {
    localStorage.setItem('paidMediaObject', JSON.stringify(mediaObj));
    document.getElementById('pay-for-media-confirmation').classList.remove('d-none');
  };

  cancelPaymentForPaidMedia = () => {
    document.getElementById('pay-for-media-confirmation').classList.add('d-none');
    localStorage.removeItem('paidMediaObject');
  };

  payForMedia = () => {
    let paidMediaObject = localStorage.getItem('paidMediaObject');
    paidMediaObject = JSON.parse(paidMediaObject);
    this.props.paidmediarequest(
      paidMediaObject.media_price,
      paidMediaObject.product_id
    );
  };

  openImagePopup = (mediaObj) => {
    document.getElementsByClassName('post-dialog')[0].classList.remove('d-none');
    this.setState({ popupData: mediaObj });
  };

  cancelImagePopup = () => {
    document.getElementsByClassName('post-dialog')[0].classList.add('d-none');
    this.setState({ popupData: null });
  };

  deleteMedia = async (mediaId) => {
    document.body.style.cursor = 'wait';
    this.setState({ isLoading: true });
    const userName = this.props.userdetail.username;
    try {
      await MediaManagement.deleteMedia(userName, mediaId);
      this.setState(
        {
          posts: [],
          photos: [],
          videos: [],
        },
        () => {
          this.loadUsers();
          this.cancelImagePopup();
        }
      );
    } catch (e) {
      console.error(e);
    }
    document.body.style.cursor = 'default';
  };

  isOwner() {
    return this.props.userrole === 'owner';
  }

  render() {
    let { error, hasMore, isLoading, posts, videos, photos } = this.state;

    let { userrole } = this.props;
    if (posts && posts.length > 0) {
      posts = posts.sort(function (a, b) {
        return b.created_date - a.created_date;
      });
      posts.map(function (d, i) {
        photosComponent.push(
          <div
            key={d.media_id}
            className="post-grid-box"
            id={`media_${d.media_id}`}
            onClick={() => {
              this.openImagePopup(d);
            }}
          >
            <img src={d.media_url} alt={d.media_title} />
            <a href="#">
              <div className="likes mr-4">
                <span>
                  <i className="icon-heart mr-2"></i>
                  <span>75</span>
                </span>
              </div>
              <div className="comments mr-4">
                <span>
                  <i className="icon-message mr-2"></i>
                  <span>60</span>
                </span>
              </div>
            </a>
          </div>
        );
      });
    }

    let photosComponent = (
      <div className="media-not-available">
        <img src={noPhotoImage} alt="" />
        <p className="mt-4">Photos not available</p>
      </div>
    );
    if (photos && photos.length > 0) {
      photos = photos.sort(function (a, b) {
        return b.created_date - a.created_date;
      });
      photosComponent = [];
      photos.map((d, i) => {
        if (this.isOwner()) {
          photosComponent.push(
            <div
              key={d.media_id}
              className="post-grid-box"
              id={`media_${d.media_id}`}
              onClick={() => {
                this.openImagePopup(d);
              }}
            >
              <img src={d.media_url} alt={d.media_title} />
              <a href="#">
                <div className="likes mr-4">
                  <span>
                    <i className="icon-heart mr-2"></i>
                    <span>75</span>
                  </span>
                </div>
                <div className="comments mr-4">
                  <span>
                    <i className="icon-message mr-2"></i>
                    <span>60</span>
                  </span>
                </div>
              </a>
            </div>
          );
        } else if (userrole === 'visitor') {
          if (d.security_attribute === 'subscribed') {
            photosComponent.push(
              <div
                key={d.media_id}
                className="post-grid-box locked"
                id={`media_${d.media_id}`}
              >
                <button
                  className="btn-payment subscribe"
                  onClick={this.subscriptionRequest}
                >
                  <span>Subscribe</span>to see this user’s posts
                </button>
                <img src={blurImg} alt="" />
              </div>
            );
          } else if (d.security_attribute === 'paid') {
            photosComponent.push(
              <div
                key={d.media_id}
                className="post-grid-box locked"
                id={`media_${d.media_id}`}
              >
                <button
                  className="btn-payment"
                  onClick={() => {
                    this.payConfirmation(d);
                  }}
                >
                  Pay <span>${d.media_price}</span>to unlock
                </button>
                <img src={blurImg} alt="" />
              </div>
            );
          } else {
            photosComponent.push(
              <div
                key={d.media_id}
                className="post-grid-box"
                id={`media_${d.media_id}`}
                onClick={() => {
                  this.openImagePopup(d);
                }}
              >
                <img src={d.media_url} alt={d.media_title} />
                <a href="#">
                  <div className="likes mr-4">
                    <span>
                      <i className="icon-heart mr-2"></i>
                      <span>75</span>
                    </span>
                  </div>
                  <div className="comments mr-4">
                    <span>
                      <i className="icon-message mr-2"></i>
                      <span>60</span>
                    </span>
                  </div>
                </a>
              </div>
            );
          }
        } else {
          if (d.security_attribute === 'paid') {
            photosComponent.push(
              <div
                key={d.media_id}
                className="post-grid-box locked"
                id={`media_${d.media_id}`}
              >
                <button
                  className="btn-payment"
                  onClick={() => {
                    this.payConfirmation(d);
                  }}
                >
                  Pay <span>${d.media_price}</span>to unlock
                </button>
                <img src={blurImg} alt="" />
              </div>
            );
          } else {
            photosComponent.push(
              <div
                key={d.media_id}
                className="post-grid-box"
                id={`media_${d.media_id}`}
                onClick={() => {
                  this.openImagePopup(d);
                }}
              >
                <img src={d.media_url} alt={d.media_title} />
                <a href="#">
                  <div className="likes mr-4">
                    <span>
                      <i className="icon-heart mr-2"></i>
                      <span>75</span>
                    </span>
                  </div>
                  <div className="comments mr-4">
                    <span>
                      <i className="icon-message mr-2"></i>
                      <span>60</span>
                    </span>
                  </div>
                </a>
              </div>
            );
          }
        }
      });
    }

    let videosComponent = (
      <div className="media-not-available">
        <img src={noVideoImage} alt="" />
        <p className="mt-4">Videos not available</p>
      </div>
    );
    if (videos && videos.length > 0) {
      videos = videos.sort(function (a, b) {
        return b.created_date - a.created_date;
      });
      videosComponent = [];
      videos.forEach((video) => {
        if (userrole === 'owner') {
          const mediaIsNotPublished = video.media_state == 0;
          if (mediaIsNotPublished) {
            videosComponent.push(
              <div
                key={video.media_id}
                className="post-grid-box locked"
                id={`media_${video.media_id}`}
              >
                <button className="btn-payment">
                  Your Video will be published soon.
                </button>
                <img src={blurImg} alt="" />
                <span className="play-icon">
                  <i className="far fa-play-circle"></i>
                </span>
              </div>
            );
          } else {
            let thumbnailUrl = getVideoThumbnailUrl(video);

            videosComponent.push(
              <div
                key={`video_${video.media_id}`}
                className="post-grid-box"
                id={`video_${video.media_id}`}
                onClick={() => {
                  this.openImagePopup(video);
                }}
              >
                <img src={thumbnailUrl} alt={video.media_title} />
                <a href="#">
                  <div className="likes mr-4">
                    <span>
                      <i className="icon-heart mr-2"></i>
                      <span>75</span>
                    </span>
                  </div>
                  <div className="comments mr-4">
                    <span>
                      <i className="icon-message mr-2"></i>
                      <span>60</span>
                    </span>
                  </div>
                </a>
                <span className="play-icon">
                  <i className="far fa-play-circle"></i>
                </span>
              </div>
            );
          }
        } else if (userrole === 'visitor') {
          if (video.security_attribute === 'subscribed') {
            videosComponent.push(
              <div
                key={video.media_id}
                className="post-grid-box locked"
                id={`media_${video.media_id}`}
              >
                <button
                  className="btn-payment subscribe"
                  onClick={this.subscriptionRequest}
                >
                  <span>Subscribe</span>to see this user’s posts
                </button>
                <img src={blurImg} alt="" />
                <span className="play-icon">
                  <i className="far fa-play-circle"></i>
                </span>
              </div>
            );
          } else if (video.security_attribute === 'paid') {
            videosComponent.push(
              <div
                key={video.media_id}
                className="post-grid-box locked"
                id={`media_${video.media_id}`}
              >
                <button
                  className="btn-payment"
                  onClick={() => this.payConfirmation(video)}
                >
                  Pay <span>${video.media_price}</span>to unlock
                </button>
                <img src={blurImg} alt="" />
                <span className="play-icon">
                  <i className="far fa-play-circle"></i>
                </span>
              </div>
            );
          } else {
            let thumbnailUrl = getVideoThumbnailUrl(video);
            videosComponent.push(
              <div
                key={video.media_id}
                className="post-grid-box"
                id={`media_${video.media_id}`}
                onClick={() => {
                  this.openImagePopup(video);
                }}
              >
                <i className="icon-expand mr-2"></i>
                <img src={thumbnailUrl} alt={video.media_title} />
                <a href="#">
                  {' '}
                  <div className="likes mr-4">
                    <span>
                      <i className="icon-heart mr-2"></i>
                      <span>75</span>
                    </span>
                  </div>
                  <div className="comments mr-4">
                    <span>
                      <i className="icon-message mr-2"></i>
                      <span>60</span>
                    </span>
                  </div>
                </a>
                <span className="play-icon">
                  <i className="far fa-play-circle"></i>
                </span>
              </div>
            );
          }
        } else {
          if (video.security_attribute === 'paid') {
            videosComponent.push(
              <div
                key={video.media_id}
                className="post-grid-box locked"
                id={`media_${video.media_id}`}
              >
                <button
                  className="btn-payment"
                  onClick={() => this.payConfirmation(video)}
                >
                  Pay <span>${video.media_price}</span>to unlock
                </button>
                <img src={blurImg} alt="" />
                <span className="play-icon">
                  <i className="far fa-play-circle"></i>
                </span>
              </div>
            );
          } else {
            let thumbnailUrl = getVideoThumbnailUrl(video);
            videosComponent.push(
              <div
                key={video.media_id}
                className="post-grid-box"
                id={`media_${video.media_id}`}
                onClick={() => this.openImagePopup(video)}
              >
                <img src={thumbnailUrl} alt={video.media_title} />
                <a href="#">
                  <div className="likes mr-4">
                    <span>
                      <i className="icon-heart mr-2"></i>
                      <span>75</span>
                    </span>
                  </div>
                  <div className="comments mr-4">
                    <span>
                      <i className="icon-message mr-2"></i>
                      <span>60</span>
                    </span>
                  </div>
                </a>
                <span className="play-icon">
                  <i className="far fa-play-circle"></i>
                </span>
              </div>
            );
          }
        }
      });
    }
    return (
      <div>
        <div
          id="profile-tabs"
          className="profile-tabs mt-5"
          onScroll={this.handleScroll}
        >
          <Tabs defaultActiveKey="photo" id="uncontrolled-tab-example">
            <Tab eventKey="photo" title="Photos">
              <div className="post-grid w-100">{photosComponent}</div>
            </Tab>
            <Tab eventKey="video" title="Video">
              <div className="post-grid w-100">{videosComponent}</div>
            </Tab>
          </Tabs>

          {error && <div style={{ color: '#900' }}>{error}</div>}
          {isLoading && <div>Loading...</div>}
          {!hasMore && <div hidden>You did it! You reached the end!</div>}
        </div>

        {/* payment confirmation popup */}
        <PaymentConfirmationModal
          onCancel={this.cancelPaymentForPaidMedia}
          onConfirmation={this.payForMedia}
        />

        {/* image popup */}
        <MediaModal
          media={this.state.popupData}
          onClose={this.cancelImagePopup}
          onDelete={this.deleteMedia}
          userDetail={this.props.userdetail}
          isSafari={this.state.isSafariBrowser}
          videoType={this.state.videoType}
          isOwner={this.isOwner()}
        />
      </div>
    );
  }
}
