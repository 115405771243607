import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assests/css/style.css";
import graphQLFunction from "./../graphql/graphQLFunction";
import SearchDropdown from './SearchDropdown';
import GetLoggedInUserDetail from "./utility/GetLoggedInUserDetail";
import GetLoggedInUserImage from "./utility/GetLoggedInUserImage";
export default class Header extends Component {
  constructor(props) {
    super(props); let username;
    if (this.props.auth.user){
        username = this.props.auth.user.username;
    }
    let cognitoUser = GetLoggedInUserDetail(this.props.auth);
    console.log("cognitoUser",cognitoUser);
    let profilePic = GetLoggedInUserImage(cognitoUser);
    let name =  cognitoUser['custom:cust_display_name'];
    let preferred_username = cognitoUser.preferred_username || cognitoUser['cognito:username'] || cognitoUser.Username;

    this.state = {
      username:username,
      profilePic: profilePic,
      name: name || cognitoUser.name,
      preferred_username:preferred_username,
      subscriberCount:0,
      followingCount:0,
      sellerId: null

  };
  }

  async componentDidMount(){
    let parameter = this.state.preferred_username.toLowerCase();
    console.log("UsernameSearchParams", parameter);
    let result = await graphQLFunction('UsernameSearch', parameter, "query", false);
    await this.updateState(result);
  }

  updateState = async (result) => {
    if (result && result.data && result.data.listHwm_users && result.data.listHwm_users.items && result.data.listHwm_users.items[0]) {
        let data = result.data.listHwm_users.items[0];
        console.log("data",data);
        let accID = null;
        if(data.account_id && data.account_id.startsWith("acct_")){
            
            accID = data.account_id;
        }

        this.setState({
            sellerId: accID || null
        })
        
    }
}
  
  openSidebar = event => {
    
    if(document.getElementById(event.target.id)){
           document.body.classList.add("navigation-open");
         }
  }

  closeSidebar = event => {
    
         if(document.getElementById(event.target.id)){
          document.body.classList.remove("navigation-open");
         }
  }


  render() {

    let cognitousers = this.props.auth.user;
    let addContent;

    if (cognitousers.attributes['custom:seller_check'] == 'true' || cognitousers.attributes['custom:seller_check'] == true || this.state.sellerId)
    {
      addContent = <a href="/Addcontent" className="btn btn-purple btn-add-content">
      <i className="icon-plus"></i>
      <span>Add Content</span>
    </a>
    }

    return (

      <div className="header">
      <div id="backdrop" className="nav-backdrop" onClick={this.closeSidebar}></div>
        <div className="search-section">
        <SearchDropdown {...this.props}/>
          <i className="icon-search"></i>
          <div className="notification-dropdown search-dropdown">
            <div className="overflow-hidden">
              <ul>
                <li>
                  <div className="search-result-box d-flex align-items-center">
                    <div className="search-user">
                      <img src={require("../assests/images/user.png")} alt="" />
                    </div>
                    <h5>Vera Mia</h5>
                  </div>
                </li>
                <li>
                  <div className="search-result-box d-flex align-items-center">
                    <div className="search-user">
                      <img src={require("../assests/images/user.png")} alt="" />
                    </div>
                    <h5>Vera Mia</h5>
                  </div>
                </li>
                <li>
                  <div className="search-result-box d-flex align-items-center">
                    <div className="search-user">
                      <img src={require("../assests/images/user.png")} alt="" />
                    </div>
                    <h5>Vera Mia</h5>
                  </div>
                </li>
                <li>
                  <div className="search-result-box d-flex align-items-center">
                    <div className="search-user">
                      <img src={require("../assests/images/user.png")} alt="" />
                    </div>
                    <h5>Vera Mia</h5>
                  </div>
                </li>
                <li>
                  <div className="search-result-box d-flex align-items-center">
                    <div className="search-user">
                      <img src={require("../assests/images/user.png")} alt="" />
                    </div>
                    <h5>Vera Mia</h5>
                  </div>
                </li>
                <li>
                  <div className="search-result-box d-flex align-items-center">
                    <div className="search-user">
                      <img src={require("../assests/images/user.png")} alt="" />
                    </div>
                    <h5>Vera Mia</h5>
                  </div>
                </li>
                <li>
                  <div className="search-result-box d-flex align-items-center">
                    <div className="search-user">
                      <img src={require("../assests/images/user.png")} alt="" />
                    </div>
                    <h5>Vera Mia</h5>
                  </div>
                </li>
                <li>
                  <div className="search-result-box d-flex align-items-center">
                    <div className="search-user">
                      <img src={require("../assests/images/user.png")} alt="" />
                    </div>
                    <h5>Vera Mia</h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-right-section">
          <div className="notififations">
            <a href="#" className="btn-notification">
              <i className="icon-notification"></i>
            </a>
            <div className="notification-dropdown">
              <div className="overflow-hidden">
                <ul>
                  <li>
                    <div className="subscriber-info d-flex align-items-center">
                      <div className="subscriber">
                        <div className="subscriber-img">
                          <img
                            src={require("../assests/images/user.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="subscriber-details">
                        <h5>Vera Mia</h5>
                        <p>Quisque mauris risus, faucibus sed ornare quis</p>
                      </div>
                    </div>
                    <div className="post-time">1 hour ago</div>
                  </li>
                  <li>
                    <div className="subscriber-info d-flex align-items-center">
                      <div className="subscriber">
                        <div className="subscriber-img">
                          <img
                            src={require("../assests/images/user.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="subscriber-details">
                        <h5>Daisy Shaw</h5>
                        <p>Quisque mauris risus, faucibus sed ornare quis</p>
                      </div>
                    </div>
                    <div className="post-time">2 hours ago</div>
                  </li>
                  <li>
                    <div className="subscriber-info d-flex align-items-center">
                      <div className="subscriber">
                        <div className="subscriber-img">
                          <img
                            src={require("../assests/images/user.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="subscriber-details">
                        <h5>Beatrice Parks</h5>
                        <p>Quisque mauris risus, faucibus sed ornare quis</p>
                      </div>
                    </div>
                    <div className="post-time">5 hours ago</div>
                  </li>
                  <li>
                    <div className="subscriber-info d-flex align-items-center">
                      <div className="subscriber">
                        <div className="subscriber-img">
                          <img
                            src={require("../assests/images/user.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="subscriber-details">
                        <h5>Chef Howard</h5>
                        <p>Quisque mauris risus, faucibus sed ornare quis</p>
                      </div>
                    </div>
                    <div className="post-time">8 hours ago</div>
                  </li>
                  <li>
                    <div className="subscriber-info d-flex align-items-center">
                      <div className="subscriber">
                        <div className="subscriber-img">
                          <img
                            src={require("../assests/images/user.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="subscriber-details">
                        <h5>Ashley Harris</h5>
                        <p>Quisque mauris risus, faucibus sed ornare quis</p>
                      </div>
                    </div>
                    <div className="post-time">1 day ago</div>
                  </li>
                </ul>
              </div>
              <a href="#" className="view-all">
                View All
              </a>
            </div>
          </div>
          
          {addContent}
          <span className="toggle-switch ml-4" onClick={this.openSidebar}>
            <i id="navToggle" className="fas fa-bars"></i>
          </span>
        </div>
      </div>
    );
  }
}
