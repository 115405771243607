import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import '../assests/css/style.css';
import Footer from './Footer.js';
import Sidebar from './Sidebar';
import Header from './Header';
import axios from 'axios';
import graphQLFunction from './../graphql/graphQLFunction';
import VideoPlayerAddContent from './VideoPlayerAddContent';
import config from './../config';
import GetLoggedInUserDetail from './utility/GetLoggedInUserDetail';

export const MimetypesKind = {
  opus: 'video/ogg',
  ogv: 'video/ogg',
  mp4: 'video/mp4',
  mov: 'video/mp4',
  m4v: 'video/mp4',
  mkv: 'video/x-matroska',
  m4a: 'audio/mp4',
  mp3: 'audio/mpeg',
  aac: 'audio/aac',
  oga: 'audio/ogg',
  m3u8: 'application/x-mpegURL',
  webp: 'image/webp',
  mpd: 'application/dash+xml',
};

export default class Addcontent extends Component {
  state = {
    mediaPicBase64: '',
    mediaImageFile: null,
    mediaDescription: '',
    fileName: '',
    title: '',
    titleError: '',
    amountError: '',
    maxAmountError: '',
    fileError: '',
    isLoading: false,
    accessValue: 1,
    amount: 0,
    mediaType: 1,
    fileExtension: 'jpg',
    sellerId: null,
    src: null,
    progressBarVal: '0%',
    fileSize: 0,
    isAborted: false,
    videoUploadMessage: false,
    crop: {
      unit: 'px',
      width: 300,
      aspect: 16 / 16,
    },
    errors: {
      errMessage: null,
    },
  };
  fileInputRef = React.createRef();
  videoPlayerRef = React.createRef();

  async componentDidMount() {
    try {
      let cognitoUser = GetLoggedInUserDetail(this.props.auth);
      let parameter = cognitoUser.preferred_username.toLowerCase();
      let result = await graphQLFunction(
        'UsernameSearch',
        parameter,
        'query',
        false
      );
      this.updateState(result, true);
    } catch (error) {
      console.log('error', error);
    }
  }

  updateState = (result, editProfileEnabled) => {
    if (
      result.data.listHwm_users &&
      result.data.listHwm_users.items &&
      result.data.listHwm_users.items[0]
    ) {
      let data = result.data.listHwm_users.items[0];
      this.setState({
        sellerId: data.account_id || null,
      });
    }
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value });
    this.setState({ titleError: '' });
  };

  handleDescChange = (event) => {
    this.setState({ mediaDescription: event.target.value });
  };

  onAmountChange = (event) => {
    this.setState({ amount: event.target.value });
    this.setState({ maxAmountError: '' });
    this.setState({ amountError: '' });
  };

  onAccessChange = (event) => {
    this.setState({ accessValue: parseInt(event.target.value) });
  };

  _handleImageChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];
    const splitMediaName = file.name.split('.');
    const extention = splitMediaName[1];
    let mediaType = 0;
    const images = ['svg', 'png', 'gif', 'jpeg', 'jpg'];
    if (images.includes(extention)) {
      mediaType = 1;
      //https://codesandbox.io/s/72py4jlll6?file=/src/index.js:1785-1791 img crop example
    } else {
      mediaType = 2;
    }
    reader.onloadend = () => {
      this.setState({
        mediaImageFile: file,
        mediaPicBase64: reader.result,
        fileName: file.name.replace(/ /g, ''),
        mediaType: mediaType,
        fileExtension: extention,
        fileSize: file.size,
      });
    };
    reader.readAsDataURL(file);
    document.getElementsByClassName('content-media-box')[0].classList.add('d-none');
    document.getElementsByClassName('img-preview')[0].classList.remove('d-none');
    document
      .getElementsByClassName('content-media-empty-box')[0]
      .classList.add('file-uploaded');
    this.setState({ fileError: '' });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async onCropComplete(crop) {
    if (this.imageRef && crop.width && crop.height) {
      document
        .getElementsByClassName('content-media-box')[0]
        .classList.add('d-none');
      document.getElementsByClassName('img-preview')[0].classList.remove('d-none');
      document
        .getElementsByClassName('content-media-empty-box')[0]
        .classList.add('file-uploaded');
      this.setState({ fileError: '' });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    this.setState({ mediaPicBase64: canvas.toDataURL('image/jpeg') });
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({ mediaImageFile: blob });
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  // end of image crop
  cancelImageCropPopup = () => {
    document.getElementById('image-crop-popup').classList.add('d-none');
  };

  removeImagePreview = () => {
    document
      .getElementsByClassName('content-media-box')[0]
      .classList.remove('d-none');
    document.getElementsByClassName('img-preview')[0].classList.add('d-none');
    document
      .getElementsByClassName('content-media-empty-box')[0]
      .classList.remove('file-uploaded');
    document.getElementsByClassName('file-upload-view')[0].classList.add('d-none');
    document
      .getElementsByClassName('content-media-empty-box')[0]
      .classList.remove('flex-grow-1');
    this.fileInputRef.current.value = '';
    this.setState({
      mediaImageFile: null,
      mediaPicBase64: null,
      fileName: '',
      mediaType: 1,
      fileExtension: 'jpg',
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    document
      .getElementsByClassName('file-upload-view')[0]
      .classList.remove('d-none');
    document
      .getElementsByClassName('content-media-empty-box')[0]
      .classList.add('flex-grow-1');
    if (this.state.mediaImageFile) {
      if (this.state.title) {
        if (this.state.accessValue === 3) {
          if (!this.state.amount || this.state.amount < 0) {
            this.setState({ maxAmountError: '' });
            this.setState({ fileError: '' });
            this.setState({ amountError: 'Please enter valid amount' });
          } else {
            if (this.state.amount > 0 && this.state.amount < 1001) {
              await this.uploadMediaContent();
              this.setState({ amountError: '' });
              this.setState({ maxAmountError: '' });
              this.setState({ fileError: '' });
            } else {
              this.setState({ amountError: '' });
              this.setState({ fileError: '' });
              this.setState({
                maxAmountError: 'Please enter amount between 1 to 1000',
              });
            }
          }
        } else {
          await this.uploadMediaContent();
        }
      } else {
        this.setState({ titleError: 'Please enter title' });
      }
    } else {
      this.setState({ fileError: 'Please select file' });
    }
  };

  uploadMediaContent = async () => {
    if (this.state.mediaImageFile) {
      try {
        let preSignedURLFuntion = 'PreSignedUrlImage';
        let username = this.props.auth.user.username;
        let mediaName = this.state.fileName;
        let parameter = {
          user_id: username,
          media_name: mediaName,
        };

        if (this.state.mediaType === 2) {
          parameter.media_type = this.state.mediaType;
          preSignedURLFuntion = 'PreSignedUrlVideo';
          this.setState({
            videoUploadMessage: true,
          });
        }

        this.setState({ isLoading: true });
        const response = await graphQLFunction(
          preSignedURLFuntion,
          parameter,
          '',
          false
        );

        // TODO return the url in the same property, it doesn't makes sense to use different properties
        const preSignedURLData =
          this.state.mediaType === 1
            ? response.data.preSignedUrlImage.body
            : response.data.preSignedUrlVideo.body;
        let body = JSON.parse(preSignedURLData);
        await this.uploadFileToS3(body);
      } catch (error) {
        console.log('errorPreSignedUrlPost', error);
      }
    }
  };

  uploadFileToS3(body) {
    return new Promise((resolve, reject) => {
      let mediaImageFile = this.state.mediaImageFile;
      let presignedPostData = body.s3_data.fields;
      let url = body.s3_data.url;
      const formData = new FormData();
      Object.keys(presignedPostData).forEach((key) => {
        formData.append(key, presignedPostData[key]);
      });

      formData.append('file', mediaImageFile);
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener('progress', this.progressFunction, false);
      xhr.open('POST', url, true);
      xhr.send(formData);
      xhr.onload = (result) => {
        if (this.state.mediaType === 2) {
          this.setState({
            videoUploadMessage: false,
          });
        }
        if (this.state.isAborted) {
          xhr.abort();
        }
        if (!this.state.isAborted) {
          this.CreateMedia(body);
        }
        resolve('done');
      };
    });
  }

  progressFunction = (evt) => {
    let uploadProgress = Math.round((evt.loaded / evt.total) * 100);
    if (uploadProgress <= 99) {
      this.setState({ progressBarVal: `${uploadProgress}%` });
    }
    if (uploadProgress === 100) {
      this.setState({ progressBarVal: `99%` });
    }
  };

  getVideoDuration() {
    if (this.state.mediaType === 2) {
      return this.videoPlayerRef.current.duration;
    }
    return -1;
  }

  async CreateMedia(body) {
    let username = this.props.auth.user.username;
    let mediaName = this.state.fileName;
    let splitMediaName = mediaName.split('.');
    let mediaNameWithOutExt = splitMediaName[0];

    let db_parameters = {
      media_id: body.media_id,
      user_id: username,
      media_type: `${this.state.mediaType}`,
      media_path: mediaName,
      media_price: this.state.amount,
      security_attribute: `${this.state.accessValue}`,
      created_date: parseInt(Date.now() / 1000),
    };

    if (this.state.mediaType == 2) {
      db_parameters.media_duration = this.getVideoDuration();
    }

    if (this.state.title) {
      db_parameters.media_title = this.state.title || mediaNameWithOutExt;
    }
    if (this.state.mediaDescription) {
      db_parameters.media_description = this.state.mediaDescription;
    }
    if (this.state.mediaType === 2) {
      db_parameters.media_state = 0;
    } else {
      db_parameters.media_state = 1;
    }
    if (this.state.amount > 0) {
      let productName = this.state.title || mediaNameWithOutExt;
      let cognitoUser = GetLoggedInUserDetail(this.props.auth);
      let preferredUsername = cognitoUser.preferred_username;
      var mediaProduct = {
        product_name: `"${productName}" from "${preferredUsername}"`,
        product_description:
          this.state.mediaDescription || this.state.title || mediaNameWithOutExt,
        price_amount: this.state.amount,
        seller_account_id: this.state.sellerId,
      };
      let product = await axios.post(
        `${config.api.invokeUrl}media/pricings`,
        mediaProduct
      );
      db_parameters.price_id = product.data.price_info.id;
      db_parameters.product_id = product.data.product_info.id;
    }
    await graphQLFunction('CreateHwmMedia', db_parameters, 'mutation', false);
    this.setState({ progressBarVal: '100%' });
    this.props.history.push('/Profile');
  }

  abortProcessing = () => {
    document
      .getElementsByClassName('content-media-box')[0]
      .classList.remove('d-none');
    document.getElementsByClassName('img-preview')[0].classList.add('d-none');
    document
      .getElementsByClassName('content-media-empty-box')[0]
      .classList.remove('file-uploaded');
    document.getElementsByClassName('file-upload-view')[0].classList.add('d-none');
    document
      .getElementsByClassName('content-media-empty-box')[0]
      .classList.remove('flex-grow-1');
    this.setState({
      mediaPicBase64: '',
      mediaImageFile: null,
      mediaDescription: '',
      fileName: '',
      title: '',
      titleError: '',
      amountError: '',
      maxAmountError: '',
      fileError: '',
      isLoading: false,
      accessValue: 1,
      amount: 0,
      mediaType: 1,
      fileExtension: 'jpg',
      sellerId: null,
      src: null,
      progressBarVal: '0%',
      fileSize: 0,
      videoUploadMessage: false,
      crop: {
        unit: 'px',
        width: 300,
        aspect: 16 / 16,
      },
      errors: {
        errMessage: null,
      },
    });
    this.setState({ isAborted: true });
  };

  render() {
    const {
      amount,
      amountError,
      maxAmountError,
      accessValue,
      mediaPicBase64,
      mediaType,
      fileExtension,
      progressBarVal,
      fileName,
      fileSize,
      videoUploadMessage,
    } = this.state;

    let amountField;
    if (accessValue === 3) {
      amountField = (
        <div className="input-group price-input mt-3">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input
            type="text"
            value={amount}
            onChange={this.onAmountChange}
            maxLength="4"
            className="form-control form-control-sm"
          />
          <div style={{ fontSize: 12, color: 'red' }}>
            {amountError}
            {maxAmountError}
          </div>
        </div>
      );
    }

    let imagePreview;
    if (mediaType === 1) {
      imagePreview = (
        <img className="img-fluid" src={mediaPicBase64} alt="preview-img" />
      );
    } else {
      let fileType = MimetypesKind[fileExtension.toLowerCase()];
      imagePreview = (
        <div className="img-fluid">
          <VideoPlayerAddContent
            videoRef={this.videoPlayerRef}
            media={mediaPicBase64}
            videotype={fileType}
          />
        </div>
      );
    }
    let videoUploadMessageSection = '';
    if (videoUploadMessage) {
      videoUploadMessageSection = (
        <div className="alert alert-info upload-video-info">
          Your video will be published soon!
        </div>
      );
    }
    return (
      <div>
        <div className="nav-backdrop"></div>
        <div className="main-wapper">
          <Sidebar {...this.props} />
          <div className="content-wrapper h-100">
            <Header {...this.props} />

            <div className="add-content">
              <div className="content-box">
                {videoUploadMessageSection}
                <div className="content-info">
                  <div className="media-box">
                    <div className="content-media-empty-box">
                      <div className="img-preview d-none">
                        {imagePreview}
                        <span
                          className="btn-remove"
                          onClick={this.removeImagePreview}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="content-media-box">
                        <div className="upload-icon">
                          <i className="fas fa-arrow-alt-circle-up"></i>
                        </div>
                        <p>Drag and drop or click to upload</p>
                        <div className="img-note">
                          Recommendation: Use high-quality image or video files.
                        </div>
                        <input
                          type="file"
                          id="uploadPic"
                          className="form-control"
                          onChange={this._handleImageChange}
                          ref={this.fileInputRef}
                        />
                      </div>
                    </div>
                    <div className="file-upload-view mt-4 d-none">
                      <h4>Uploading</h4>
                      <div>
                        <div className="uploaded-file-box">
                          <a
                            onClick={(e) => this.abortProcessing()}
                            className="delete-file"
                          >
                            <i className="far fa-times-circle"></i>
                          </a>
                          <div className="uploaded-file-info">
                            <h5 className="uploaded-img-name">{fileName}</h5>
                            <small className="uploaded-file-size">
                              {fileSize}KB
                            </small>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: progressBarVal }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <div className="progress-complete">
                              <span>{progressBarVal}</span> Completed
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ fontSize: 12, color: 'red' }}>
                      {this.state.fileError}
                    </div>
                  </div>
                  <div className="content-details">
                    <div className="title-input mb-5">
                      <textarea
                        placeholder="Add your title"
                        className="custom-input"
                        style={{ height: '52px' }}
                        onChange={this.handleTitleChange}
                      ></textarea>
                      <div style={{ fontSize: 12, color: 'red', marginBottom: 15 }}>
                        {this.state.titleError}
                      </div>
                      <span className="input-note">
                        Your first 30 characters are what usually show up in explore.
                      </span>
                    </div>

                    <div className="about-contetn mb-5">
                      <textarea
                        placeholder="Tell everyone what your content is about"
                        className="custom-input"
                        style={{ height: '40px' }}
                        onChange={this.handleDescChange}
                      ></textarea>
                      <span className="input-note">
                        Your first 50 characters are what usually show up in explore.
                      </span>
                    </div>

                    <div className="access-and-price">
                      <h4 className="sml-heading mb-4">Access</h4>
                      <div className="custom-control custom-radio mb-3">
                        <input
                          type="radio"
                          id="shareFree"
                          value={1}
                          name="customRadio"
                          defaultChecked
                          className="custom-control-input"
                          onChange={this.onAccessChange}
                        />
                        <label className="custom-control-label" htmlFor="shareFree">
                          Share to Everyone for FREE
                          <span>
                            (free videos must be no longer then 30 sec and will have
                            ads)
                          </span>
                        </label>
                      </div>
                      <div className="custom-control custom-radio mb-3">
                        <input
                          type="radio"
                          id="shareSubscriberOnly"
                          value={2}
                          name="customRadio"
                          className="custom-control-input"
                          onChange={this.onAccessChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="shareSubscriberOnly"
                        >
                          Share to my Subscribers Only
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="shareSubscriber"
                          value={3}
                          name="customRadio"
                          className="custom-control-input"
                          onChange={this.onAccessChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="shareSubscriber"
                        >
                          Set Additional Pay to View Price
                        </label>
                      </div>
                      {amountField}
                    </div>
                  </div>
                </div>
                <div className="text-center text-md-right mt-5 mt-md-4">
                  <button className="btn btn-purple" onClick={this.handleSubmit}>
                    Share
                  </button>
                </div>
              </div>
              <Footer {...this.props} />
            </div>
          </div>
        </div>

        {/* image crop popup */}
        <div className="custom-popup image-crop-popup d-none" id="image-crop-popup">
          <div className="popup-content white-radius-box position-relative">
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
            <div className="action-btns">
              <button className="btn btn-purple" onClick={this.cancelImageCropPopup}>
                Save
              </button>
              <button
                className="btn btn-outline"
                onClick={this.cancelImageCropPopup}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
