import React, { MouseEvent } from 'react';
import VideoPlayer from '../../VideoPlayer';
import IMedia from '../../../models/IMedia';
import MediaOptionsDropdown from './HeaderOptionsDropdown';

interface MediaModalProps {
  media: IMedia | any;
  isSafari: boolean;
  userDetail: any;
  videoType: string;
  onClose: (event: MouseEvent<HTMLAnchorElement>) => void;
  onDelete: (mediaId: string) => void;
  isOwner: boolean;
}

const MediaModal: React.FC<MediaModalProps> = (props) => {
  return (
    <div className="post-dialog d-none">
      {props.media && (
        <div className="dialog-body">
          <a
            className="btn-close-popup"
            onClick={props.onClose}
            style={{ top: '0px', left: '5px', zIndex: 10 }}
          >
            <i className="fas fa-times-circle"></i>
          </a>
          <article className="feed-box">
            <Header
              isOwner={props.isOwner}
              media={props.media}
              userDetail={props.userDetail}
              onDelete={props.onDelete}
            />
            {props.media.media_type === '2' ? (
              <figure className="post-img">
                <a href="#" className="btn-lock">
                  <i className="icon-lock"></i>
                </a>
                <VideoPlayer
                  issafari={props.isSafari}
                  idkey={`video_player_${props.media.media_id}`}
                  media={props.media}
                  videotype={props.videoType}
                />
              </figure>
            ) : (
              <figure className="post-img">
                <a href="#" className="btn-lock">
                  <i className="icon-lock"></i>
                </a>
                <img src={props.media.media_url} className="img-fluid" alt="" />
              </figure>
            )}

            <div>
              <div className="post-info">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="like-and-comments d-flex align-items-center">
                    <div className="likes mr-4">
                      <span className="liked">
                        <i className="icon-heart"></i>
                        <span>0</span>
                      </span>
                    </div>
                    <div className="comments mr-4">
                      <span>
                        <i className="icon-message"></i>
                        <span>0</span>
                      </span>
                    </div>
                    <div className="tip">
                      <span>
                        <i className="icon-tip"></i>
                        <span>Tip</span>
                      </span>
                    </div>
                  </div>
                  <div className="bookmark">
                    <a href="#" className="link-bookmark">
                      <i className="icon-bookmark"></i>
                    </a>
                  </div>
                </div>
                <div className="post-text">
                  <p className="mb-3">
                    @{props.media.user_id} {props.media.media_description}
                  </p>
                </div>
              </div>
              <div className="message-box">
                <textarea placeholder="Add Comment" rows={2}></textarea>
                <button className="btn btn-purple btn-post">Post</button>
              </div>
            </div>
          </article>
        </div>
      )}
    </div>
  );
};

interface HeaderProps {
  userDetail: any;
  media: IMedia | any;
  onDelete: (mediaId: string) => void;
  isOwner: boolean;
}

const Header: React.FC<HeaderProps> = ({ userDetail, media, onDelete, isOwner }) => {
  return (
    <header className="feed-header d-flex align-items-center justify-content-between">
      <div className="subscriber-info d-flex align-items-center">
        <div className="subscriber online">
          <div className="subscriber-img">
            <img src={userDetail.profilePic} alt="" />
          </div>
        </div>
        <div className="subscriber-details">
          <h5>{userDetail.name}</h5>
          <p>@{userDetail.preferred_username}</p>
        </div>
      </div>
      <div className="subscriber-feed-info d-flex align-items-center">
        <div className="d-flex flex-column align-items-end">
          <div className="post-time">6 hours ago</div>
          <div className="post-price">$5.50</div>
        </div>
        <div className="more-info ml-4">
          {isOwner && <MediaOptionsDropdown media={media} onDelete={onDelete} />}
        </div>
      </div>
    </header>
  );
};

export default MediaModal;
