import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import GetLoggedInUserDetail from './utility/GetLoggedInUserDetail';
import GetLoggedInUserImage from './utility/GetLoggedInUserImage';
import graphQLFunction from './../graphql/graphQLFunction';
import config from './../config';
import axios from 'axios';
const queryString = require('query-string');
Amplify.configure(config.graphQL);

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    let username;
    if (this.props.auth.user) {
      username = this.props.auth.user.username;
    }
    let cognitoUser = GetLoggedInUserDetail(this.props.auth);
    console.log('cognitoUser', cognitoUser);
    let profilePic = GetLoggedInUserImage(cognitoUser);
    let name = cognitoUser['custom:cust_display_name'];
    let preferred_username =
      cognitoUser.preferred_username ||
      cognitoUser['cognito:username'] ||
      cognitoUser.Username;
    this.state = {
      username: username,
      profilePic: profilePic,
      name: name || cognitoUser.name,
      preferred_username: preferred_username,
      subscriberCount: 0,
      followingCount: 0,
      sellerId: null,
    };
  }
  abortController = new AbortController();
  async componentDidMount() {
    try {
      let parameter = `object_type:"summary#${this.state.username}",object_id:"followerscount"`;
      let subscriberCount = await graphQLFunction(
        'GetFollower',
        parameter,
        'query',
        false
      );
      if (
        subscriberCount.data.getLookupsDev &&
        subscriberCount.data.getLookupsDev.value
      ) {
        this.setState({ subscriberCount: subscriberCount.data.getLookupsDev.value });
      }
      let parameter1 = `object_type:"summary#${this.state.username}",object_id:"followingcount"`;
      let followingCount = await graphQLFunction(
        'GetFollower',
        parameter1,
        'query',
        false
      );
      if (
        followingCount.data.getLookupsDev &&
        followingCount.data.getLookupsDev.value
      ) {
        this.setState({ followingCount: followingCount.data.getLookupsDev.value });
      }

      let cognitoUser = GetLoggedInUserDetail(this.props.auth);
      let param = cognitoUser.preferred_username.toLowerCase();
      let result = await graphQLFunction('UsernameSearch', param, 'query', false);
      this.updateState(result, true);
    } catch (error) {
      console.log('error in getting subscriberCount', error);
    }
  }

  updateState = (result) => {
    if (
      result.data.listHwm_users &&
      result.data.listHwm_users.items &&
      result.data.listHwm_users.items[0]
    ) {
      let data = result.data.listHwm_users.items[0];
      let accID = null;
      if (data.account_id && data.account_id.startsWith('acct_')) {
        accID = data.account_id;
        this.setState({
          sellerId: accID,
        });
        console.log('sellerId', accID);
      }
    }
  };

  componentWillUnmount() {
    this.abortController.abort();
  }

  handleLogOut = async (event) => {
    event.preventDefault();
    try {
      localStorage.removeItem('userProfile');
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.props.history.push('/Loginpage');
    } catch (error) {
      console.log(error.message);
    }
  };

  handlePayout = async (event) => {
    event.preventDefault();

    var data = {
      seller_account_id: this.state.sellerId,
    };

    axios
      .post(`${config.api.invokeUrl}connect/login`, data, {})
      .then(async (res) => {
        console.log('seller_account_id', this.state.sellerId);

        console.log('payoutResponse', res);

        console.log('payoutResponseUrl', res.data.data.url);
        window.open(res.data.data.url, '_blank');
        // window.location.assign(res.data.data.url);
      })
      .catch((error) => {
        return error;
      });
  };

  render() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const {
      profilePic,
      name,
      preferred_username,
      subscriberCount,
      followingCount,
    } = this.state;

    let sellContent;
    let myPayouts;
    let user = localStorage.getItem('user');
    //   console.log("sidebar",user);
    let cognitousers = this.props.auth.user;
    //   console.log("cognitousers",this.props.auth.user);

    if (
      cognitousers.attributes['custom:seller_check'] === 'false' ||
      cognitousers.attributes['custom:seller_check'] === false ||
      this.state.sellerId === null
    ) {
      if (code === null) {
        sellContent = (
          <li>
            <a href="/SellContent" className="nav-link">
              <i className="fas fa-money-check-alt"></i>Sell Your Content
            </a>
          </li>
        );
      }
    } else {
      myPayouts = (
        <li>
          <a href="/Mypayouts" className="nav-link">
            <i className="fas fa-file-invoice-dollar"></i>My Payouts
          </a>
        </li>
      );
    }

    return (
      <aside className="left-navigation" id="sidebar">
        <div className="logo">
          <a href="/">
            <img
              src={require('../assests/images/logo-with-text.svg')}
              className="img-fluid"
              alt=""
            />
          </a>
        </div>
        <div className="user-info">
          <a href="/Profile" className="d-block">
            <div className="user-img">
              <img src={profilePic} alt="" />
            </div>
            <h4 className="full-name">{name}</h4>
            <h6 className="user-name">@{preferred_username}</h6>
          </a>
        </div>
        <div className="feed-info">
          <div className="d-flex flex-column align-items-center">
            <span className="count">{subscriberCount}</span>
            <span className="text">Subscribers</span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className="count">{followingCount}</span>
            <span className="text">Following</span>
          </div>
        </div>

        <ul className="navigation">
          <li className="active">
            <a href="/Homefeed" className="nav-link">
              <i className="icon-home"></i>Home
            </a>
          </li>
          <li>
            <a href="/Explore" className="nav-link">
              <i className="icon-explore"></i>Explore
            </a>
          </li>
          {/* <li>
                    <a href="/Bookmark" className="nav-link">
                        <span className="d-flex align-items-center flex-grow-1">
                            <i className="icon-bookmark"></i>
                            <span>Bookmarks</span>
                        </span>
                        <span>0</span>
                    </a>
                </li> */}

          {sellContent}
          {myPayouts}

          <li className="dropdown">
            <a href="#" className="nav-link">
              <i className="icon-preference"></i>Settings
            </a>
            <ul className="custom-dropdown-menu">
              <li>
                <a
                  className="dropdown-link"
                  href="#"
                  data-toggle="modal"
                  data-target="#changePassword"
                >
                  Change Password
                </a>
              </li>
              <li>
                <a className="dropdown-link" href="#">
                  Privacy Setting
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" onClick={this.handleLogOut} className="nav-link">
              <i className="icon-logout"></i>Logout
            </a>
          </li>
        </ul>
      </aside>
    );
  }
}
