import { API, Auth } from 'aws-amplify';

class MediaManagementService {
  private async getJwtToken(): Promise<string> {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  }

  async deleteMedia(user_id: string, media_id: string): Promise<void> {
    const jwtToken = await this.getJwtToken();
    await API.del('MediaManagement', `/v1/media`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: {
        user_id,
        media_id,
      },
    });
  }
}

const mediaManagementServiceInstance = new MediaManagementService();

export default mediaManagementServiceInstance;
