const PATTERN_DASH = '/DASH/';
const PATTERN_HLS = '/HLS/';
const SEGMENT_NUMBERING_LENGTH = 7;
const SEGMENT_APPROXIMATE_DURATION_IN_SECONDS = 5;

export function getVideoThumbnailUrl(video: any): string | undefined {
  const mediaPath = video.media_url.split('?')[0];
  const start_dash = mediaPath.indexOf(PATTERN_DASH);
  const start_hls = mediaPath.indexOf(PATTERN_HLS);
  let currentIndex = -1;
  if (start_dash > -1) {
    currentIndex = start_dash;
  } else if (start_hls > -1) {
    currentIndex = start_hls;
  }

  if (currentIndex <= -1) {
    return;
  }

  const thumbnailPath = mediaPath.substring(0, currentIndex);
  const title = thumbnailPath.substr(thumbnailPath.lastIndexOf('/') + 1);
  const segmentNumbering = getMiddleSegmentFromDuration(video.media_duration);
  return `${thumbnailPath}/Thumbnails/${title}.${segmentNumbering}.jpg`;
}

/**
 * Returns the middle segment of the video
 * given the duration. Ex: 0000010
 * @return {string} segment number
 */
function getMiddleSegmentFromDuration(duration: number | undefined): string {
  if (!duration || duration <= SEGMENT_APPROXIMATE_DURATION_IN_SECONDS) {
    return '0'.repeat(SEGMENT_NUMBERING_LENGTH);
  }
  const middle = Math.round(duration / 2);
  const segmentNumbering = Math.round(
    SEGMENT_APPROXIMATE_DURATION_IN_SECONDS / middle
  ).toString();
  const numberingPadding = '0'.repeat(
    SEGMENT_NUMBERING_LENGTH - segmentNumbering.length
  );
  return `${numberingPadding}${segmentNumbering}`;
}
