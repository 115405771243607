import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assests/css/style.css";


export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render(){
  return (

    <footer className="login-footer text-center">
      <ul>
          <li><a href="/About">About HeyWatch.Me</a></li>
          <li><a href="/Service">Terms of Service</a></li>
          <li><a href="PrivacyPolicy">Privacy Policy</a></li>
          <li><a href="/ContactUs">Contact Us</a></li>
          <li><a href="Help">Help</a></li>
      </ul>
  </footer>

    )
  }
}
