import React, { Component } from 'react';
import FormErrors from "./utility/FormErrors";
import { validateForm }  from "./utility/FormValidation";
import { Auth } from 'aws-amplify';
import landingLogo from "../assests/images/logo.svg";
import Footer from './Footer';

class ForgotPassword extends Component {
  state = {
    email: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  forgotPasswordHandler = async event => {
    console.log("Forget Password Handler called")
    event.preventDefault();

  //  Form validation
    this.clearErrorState();
    const error = validateForm(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      await Auth.forgotPassword(this.state.email);
      this.props.history.push('/forgotpasswordverification');
    }catch(error) {
      console.log(error);
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  addBodyClass = () => {
    document.body.classList.add("bodyStyle");
  }

  render() {
    this.addBodyClass();

    return (
      <section className="section auth">
         <div className="wrapper section auth">
          <div className="login d-flex flex-column flex-grow-1">
            <div className="flex-grow-1 d-flex justify-content-center align-items-sm-center">
              <div className="white-radius-box box d-flex flex-column">
                <a href="/" className="login-logo">
                  <img src={landingLogo} alt="" />
                </a>
                <h5 className="box-heading text-center">
                  Forgot
                  <br />
                  Password
                </h5>
          <FormErrors formerrors={this.state.errors} />

          <form onSubmit={this.forgotPasswordHandler}>
          <div className="row login-fields">
                    <div className="col-12">
                      <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
                </div>
                    </div>
                  </div>
                <div className="mt-5">
                <button className="btn btn-purple w-100">
                  Submit
                </button>

                <p className="already-member text-center mt-4 mb-0">
                  <a href='/Loginpage' className="text-decoration-none">
                    Back
                  </a>
                </p>
                </div>
                </form>
              
              </div>
            </div>
            
            <Footer />
            
          </div>
        </div>
        </section>
    );
  }
}
export default ForgotPassword;