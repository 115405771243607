import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import '../assests/css/style.css';
import buyer from '../assests/images/confirmation-graphic.svg';
import stripe from '../assests/images/stripe.png';
import config from './../config';
import graphQLFunction from './../graphql/graphQLFunction';
import GetLoggedInUserDetail from './utility/GetLoggedInUserDetail';
const queryString = require('query-string');
Amplify.configure(config.graphQL);

export default class Welcome extends Component {
  constructor(props) {
    super(props);
    const isUserLoggedIn = this.checkIsUserLoggedIn();
    if (!isUserLoggedIn) {
      this.props.history.push('/Loginpage');
    }
    this.state = {};
    this.redirectTo();
  }
  checkIsUserLoggedIn = () => {
    return (
      this.props &&
      this.props.auth &&
      this.props.auth.isAuthenticated &&
      this.props.auth.user
    );
  };
  componentDidMount() {
    this.redirectTo();
  }
  redirectTo = () => {
    let cognitoUser = GetLoggedInUserDetail(this.props.auth);
    let exitLogin = false;
    if (cognitoUser['custom:exist_login']) {
      exitLogin = cognitoUser['custom:exist_login'];
    }
    if (exitLogin === 'true' || exitLogin === true || exitLogin === 'done') {
      this.props.history.push('/Homefeed');
    }
  };

  handleStripeAuthorize = async (element) => {
    let cognitoUser = GetLoggedInUserDetail(this.props.auth);
    var oneYearFromNow = new Date();
    let username = '';
    if (this.props.auth.user) {
      username = this.props.auth.user.username;
    }
    try {
      let user = this.props.auth.user;
      let attributes = {
        'custom:exist_login': 'done',
        'custom:seller_check': 'false',
        'custom:description': ' ',
        website: '',
        'custom:dob': new Date(
          oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 20)
        ).toDateString(),
        'custom:cust_display_name': cognitoUser.name,
        preferred_username: username,
      };
      if (!cognitoUser.picture) {
        attributes[
          'custom:cust_profile_pic'
        ] = `${config.api.s3BucketURL}/unknown.jpg`;
      }
      await Auth.updateUserAttributes(user, attributes);
      const users = await Auth.currentAuthenticatedUser({ bypassCache: true });
      this.props.auth.setUser(users);

      let displayName = cognitoUser.name + '#' + username;
      displayName = displayName.toLowerCase();
      let name = cognitoUser.name.split(' ');
      let parameter = {
        user_id: username,
        email: cognitoUser.email,
        caption: '',
        first_name: name[0] || 'null',
        last_name: name[1] || 'null',
        username: username,
        display_name: `#${username}`,
        display_name_ls: `#${displayName}`,
      };
      await graphQLFunction('CreateUser', parameter, 'mutation', false);
    } catch (error) {
      console.log('errorCreateUser', error);
    }

    if (element === 'no') {
      this.props.history.push('/Editprofile');
    } else {
      const state = Math.random().toString(36).slice(2);
      let parameters = {
        client_id: config.stripe.clientId,
        state: state,
        redirect_uri: config.stripe.publicDomain + 'EditProfile',
        'stripe_user[business_type]': 'individual',
        'stripe_user[business_name]': 'Software',
        'stripe_user[first_name]': cognitoUser.name,
        'stripe_user[last_name]': cognitoUser.name,
        'stripe_user[email]': cognitoUser.email,
        'stripe_user[country]': 'US',
        'suggested_capabilities[]': 'card_payments',
        'stripe_user[dob_day]': '01',
        'stripe_user[dob_month]': '01',
        'stripe_user[dob_year]': '2001',
      };
      window.location.assign(
        config.stripe.authorizeUri + '?' + queryString.stringify(parameters)
      );
    }
  };
  render() {
    return (
      <div className="fullpage-popup confirmation-popup">
        <div className="popup-content white-radius-box">
          <div className="graphics">
            <img src={buyer} alt="" />
          </div>
          <h3 className="text">Are you going to sell content?</h3>
          <div className="d-flex action-btns">
            <a
              onClick={() => {
                this.handleStripeAuthorize('yes');
              }}
              className="btn btn-outline"
            >
              Yes
            </a>
            <a
              onClick={() => {
                this.handleStripeAuthorize('no');
              }}
              className="btn btn-outline"
            >
              No
            </a>
          </div>
          <p className="stripe-note">
            We use Stripe to make sure you get paid on time and to keep your personal
            bank and details secure. <span>Click Yes</span> to set up your payments
            on Stripe.
            <span className="logo-stripe">
              <img src={stripe} className="img-fluid" alt="" />
            </span>
          </p>
        </div>
      </div>
    );
  }
}
