import React, { Component } from 'react';
import '../assests/css/style.css';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import config from './../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import GetLoggedInUserDetail from './utility/GetLoggedInUserDetail';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Header from './Header';
import graphQLFunction from './../graphql/graphQLFunction';
import GetLoggedInUserImage from './utility/GetLoggedInUserImage';
Amplify.configure(config.graphQL);

const defaultState = {
  nameError: '',
  websiteError: '',
  usernameError: '',
  spaceError: '',
  promoAmtError: '',
  promoMonthError: '',
  subAmtError: '',
};
export default class EditProfile extends Component {
  constructor(props) {
    super(props);
    var oneYearFromNow = new Date();
    let profileImage = '';
    let cognitoUser = GetLoggedInUserDetail(this.props.auth);
    let profilePic = GetLoggedInUserImage(cognitoUser);
    let displayName = cognitoUser['custom:cust_display_name'];
    let description = cognitoUser['custom:description'];
    let website = cognitoUser.website;
    let dob = cognitoUser['custom:dob'];
    let preferred_username =
      cognitoUser.preferred_username ||
      cognitoUser['cognito:username'] ||
      cognitoUser.Username;
    let email = cognitoUser.email;
    let cancelBtnLabel = 'Cancel';

    if (cognitoUser['custom:exist_login'] === 'done') {
      cancelBtnLabel = 'Skip';
    }

    this.state = {
      displayName: displayName || cognitoUser.name,
      preferred_username: preferred_username,
      profilePic: profilePic,
      email: email,
      description: description,
      website: website,
      amount: 0,
      prevAmount: 0,
      promotionAmount: 0.0,
      prevPromotionAmount: 0.0,
      promotionMonth: 0,
      prevPromotionMonth: 0,
      promoAmtError: '',
      promoMonthError: '',
      subAmtError: '',
      dob:
        dob ||
        new Date(
          oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 20)
        ).toDateString(),
      profileImage: profileImage,
      isImageUploaded: false,
      profilePicLocal: null,
      sellerId: null,
      cancelBtnLabel: cancelBtnLabel,
      stripeAccountStatus: false,
      flatProductMonth: 1,
      errors: {
        errMessage: null,
      },
    };

    const isUserLoggedIn = this.checkIsUserLoggedIn();
    if (!isUserLoggedIn) {
      this.props.history.push('/Loginpage');
    }
  }

  async componentDidMount() {
    let preferredUsername = this.state.preferred_username.toLowerCase();
    let result = await graphQLFunction(
      'UsernameSearch',
      preferredUsername,
      'query',
      false
    );
    const updatedState = {
      ...this.state,
      ...this.updateState(result),
    };
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code) {
      // TODO compare state is equal in FE or BE to avoid CSRF
      const res = await axios.get(
        `${config.api.invokeUrl}token?code=${code}&state=${state}`
      );
      this.props.history.replace('/EditProfile');
      let sellerId = res.data.data;
      const {
        displayName,
        email,
        description,
        preferred_username,
        amount,
        promotionAmount,
        promotionMonth,
      } = updatedState;
      let username = this.props.auth.user.username;
      let display_name_ls = displayName + '#' + preferred_username;
      display_name_ls = display_name_ls.toLowerCase();
      let nameArr = displayName.split(' ');
      let parameter = {
        user_id: username,
        email: email,
        caption: description || ' ',
        first_name: nameArr[0] || 'null',
        last_name: nameArr[1] || 'null',
        username: preferred_username,
        display_name: `#${displayName}`,
        display_name_ls: `#${display_name_ls}`,
        flat_subscription_amount: amount || 0,
        promotion_amount: promotionAmount || 0,
        promotion_month: promotionMonth || 0,
        account_id: sellerId,
      };

      localStorage.setItem('sellerId', JSON.stringify(sellerId));

      await graphQLFunction('UpdateUser', parameter, 'mutation', false);

      if (sellerId) {
        try {
          const data = {
            seller_account_id: sellerId,
          };
          await axios.post(`${config.api.invokeUrl}connect/updateAccount`, data, {});
          this.setState({ sellerId: sellerId });
        } catch (error) {
          console.log(error);
        }

        let user = this.props.auth.user;
        let attributes = {
          'custom:seller_check': 'true',
          'custom:exist_login': 'true',
        };

        await Auth.updateUserAttributes(user, attributes);
        window.location.reload();
      }
    }

    if (this.state.sellerId) {
      this.checkStripeAccountStatus();
    }
  }

  checkIsUserLoggedIn = () => {
    return (
      this.props &&
      this.props.auth &&
      this.props.auth.isAuthenticated &&
      this.props.auth.user
    );
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove('is-danger');
  };

  onAmountChange = (event) => {
    this.setState({ amount: event.target.value });
    this.setState({ subAmtError: '' });
  };

  onFlatProductMonthChange = (event) => {
    this.setState({ flatProductMonth: parseInt(event.target.value) });
  };

  onPromotionAmtChange = (event) => {
    this.setState({ promotionAmount: event.target.value });
    this.setState({ promoAmtError: '' });
  };

  onPromotionMonthChange = (event) => {
    this.setState({ promotionMonth: event.target.value });
    this.setState({ promoMonthError: '' });
  };

  checkStripeAccountStatus = async () => {
    try {
      const stripeAccountStatusResponse = await axios.get(
        `${config.api.invokeUrl}connect/accounts/${this.state.sellerId}`
      );
      let inactiveStatus = stripeAccountStatusResponse.data.message.includes(
        'not activated'
      );
      this.setState({ stripeAccountStatus: inactiveStatus ? 'inactive' : 'active' });
    } catch (error) {
      console.error(error);
      this.setState({ stripeAccountStatus: 'inactive' });
    }
  };

  updateState = (result) => {
    if (
      result &&
      result.data &&
      result.data.listHwm_users &&
      result.data.listHwm_users.items &&
      result.data.listHwm_users.items.length > 0
    ) {
      let data = result.data.listHwm_users.items.find(
        (user) => user.username === this.state.preferred_username
      );
      let displayName = null;
      if (data.display_name) {
        displayName = data.display_name.substr(1);
      }
      let accID = null;
      if (data.account_id && data.account_id.startsWith('acct_')) {
        accID = data.account_id;
      }
      const newState = {
        email: data.email,
        name: displayName || ' ',
        preferred_username: data.username,
        description: data.caption,
        username: data.user_id,
        amount: data.flat_subscription_amount || 0,
        prevAmount: data.flat_subscription_amount || 0,
        flatProductMonth: Number.isInteger(parseInt(data.flat_product_month))
          ? data.flat_product_month
          : 1 || 1,
        promotionAmount: data.promotion_amount || 0,
        prevPromotionAmount: data.promotion_amount || 0,
        promotionMonth: data.promotion_month || 0,
        prevPromotionMonth: data.promotion_month || 0,
        sellerId: accID || null,
      };
      this.setState(newState);
      return newState;
    } else {
      throw new Error('User was not found');
    }
  };

  validate = () => {
    let nameError = '';
    let usernameError = '';
    let spaceError = '';
    if (!this.state.displayName || this.state.displayName === '') {
      nameError = 'Name cannot be blank';
    }
    if (!this.state.preferred_username || this.state.preferred_username === '') {
      usernameError = 'Username cannot be blank';
    }
    if (this.state.preferred_username.indexOf(' ') !== -1) {
      spaceError = 'space is not allowed in username';
    }

    if (nameError || usernameError || spaceError) {
      this.setState({ nameError, usernameError, spaceError });
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid === true) {
      if (this.state.amount < 0) {
        this.setState({ subAmtError: 'Please enter valid amount' });
      } else {
        if (this.state.amount > 1000) {
          this.setState({
            subAmtError: 'Please enter amount between 1 to 1000',
          });
        } else {
          this.setState({ subAmtError: '' });
          if (this.state.subAmtError == '') {
            await this.callAwsUpdateProfile();
            this.setState(defaultState);
          }
        }
      }
    }
  };

  callAwsUpdateProfile = async () => {
    this.showLoader();
    if (!this.state.displayName || !this.state.preferred_username) {
      return false;
    } else if (this.state.profileImage) {
      var data = {
        path:
          this.props.auth.user.username +
          '/' +
          this.props.auth.user.username +
          '.jpg',
      };
      axios
        .post(`${config.api.s3URL}profileImage`, data, {})
        .then(async (res) => {
          await this.uploadFileToS3(
            res.data.data,
            res.data.data.url,
            this.state.profileImage
          );
        })
        .catch((error) => {
          return error;
        });
    } else {
      await this.uploadProfileOnCognito();
    }
  };

  uploadFileToS3 = async (presignedPostData, url, profileImage) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });

      formData.append('file', profileImage);
      const xhr = new XMLHttpRequest();
      xhr.open('POST', presignedPostData.url, true);
      xhr.send(formData);
      xhr.onload = () => {
        axios({
          method: 'get',
          url: `${config.api.s3URL}profileImage?user_id=${this.props.auth.user.username}&user_name=${this.props.auth.user.username}.jpg`,
        })
          .then(async (response) => {
            let profilePic = response.data.data.split('?');
            profilePic = profilePic[0];
            this.setState({ profilePic: profilePic });
            await this.uploadProfileOnCognito();
          })
          .catch((error) => {
            console.log(error);
          });
      };
    });
  };

  uploadProfileOnCognito = async (event) => {
    const {
      displayName,
      email,
      dob,
      website,
      description,
      preferred_username,
      profilePic,
      sellerId,
      amount,
      promotionAmount,
      promotionMonth,
      prevAmount,
      prevPromotionAmount,
      prevPromotionMonth,
      flatProductMonth,
    } = this.state;
    let user = this.props.auth.user;
    let attributes = {
      'custom:exist_login': 'true',
      'custom:description': description || ' ',
      'custom:dob': dob,
      website: website || '',
      'custom:cust_display_name': displayName,
      preferred_username: preferred_username,
    };

    if (this.state.isImageUploaded) {
      attributes['custom:cust_profile_pic'] = profilePic;
      let userProfile = {
        profilePicLocal: this.state.profilePicLocal,
      };
      localStorage.setItem('userProfile', JSON.stringify(userProfile));
    }

    await Auth.updateUserAttributes(user, attributes);

    let username = this.props.auth.user.username;
    let display_name_ls = displayName + '#' + preferred_username;
    display_name_ls = display_name_ls.toLowerCase();
    let nameArr = displayName.split(' ');

    if (sellerId && amount !== prevAmount) {
      var productData = {
        product_name: `"${preferred_username}"`,
        product_description: description,
        interval_count: flatProductMonth,
        price_amount: amount,
        seller_account_id: sellerId,
      };

      try {
        let product = await axios.post(
          `${config.api.invokeUrl}products`,
          productData
        );
        let param = `object_type:"product_follow",object_id:"${username}"`;
        let getProduct = await graphQLFunction(
          'GetFollowing',
          param,
          'query',
          false
        );
        if (
          getProduct &&
          getProduct.data &&
          getProduct.data.getLookupsDev &&
          getProduct.data.getLookupsDev.product_id
        ) {
          let parameter = `{object_type:"product_follow", object_id: "${username}", account_id: "${this.state.sellerId}", pricing_id: "${product.data.price_info.id}", product_id: "${product.data.product_info.id}"}`;
          await graphQLFunction('Follow', parameter, 'query', false);
        } else {
          let parameter = `{object_type:"product_follow#${username}", object_id: "${username}", account_id: "${this.state.sellerId}", pricing_id: "${product.data.price_info.id}", product_id: "${product.data.product_info.id}"}`;
          await graphQLFunction('CreateProduct', parameter, 'query', false);
        }
      } catch (error) {
        console.log('error', error);
      }
    }

    if (
      sellerId &&
      (promotionAmount !== prevPromotionAmount ||
        promotionMonth !== prevPromotionMonth)
    ) {
      var productPromotionData = {
        product_name: `"${preferred_username}"`,
        product_description: description,
        interval_count: promotionMonth,
        price_amount: promotionAmount,
        seller_account_id: sellerId,
      };
      try {
        let product = await axios.post(
          `${config.api.invokeUrl}products`,
          productPromotionData
        );
        let param = `object_type:"product_follow_promotion",object_id:"${username}"`;
        let getProduct = await graphQLFunction(
          'GetFollowing',
          param,
          'query',
          false
        );
        if (
          getProduct &&
          getProduct.data &&
          getProduct.data.getLookupsDev &&
          getProduct.data.getLookupsDev.product_id
        ) {
          let parameter = `{object_type:"product_follow_promotion", object_id: "${username}", account_id: "${this.state.sellerId}", pricing_id: "${product.data.price_info.id}", product_id: "${product.data.product_info.id}"}`;
          await graphQLFunction('Follow', parameter, 'query', false);
        } else {
          let parameter = `{object_type:"product_follow_promotion#${username}", object_id: "${username}", account_id: "${this.state.sellerId}", pricing_id: "${product.data.price_info.id}", product_id: "${product.data.product_info.id}"}`;
          await graphQLFunction('CreateProduct', parameter, 'query', false);
        }
      } catch (error) {
        console.log('productPromotionData error', error);
      }
    }

    let parameter = {
      user_id: username,
      email: email,
      caption: description || ' ',
      first_name: nameArr[0] || 'null',
      last_name: nameArr[1] || 'null',
      username: preferred_username,
      display_name: `#${displayName}`,
      display_name_ls: `#${display_name_ls}`,
      flat_subscription_amount: amount,
      flat_product_month: flatProductMonth,
      promotion_amount: promotionAmount,
      promotion_month: promotionMonth,
      account_id: sellerId,
    };
    await graphQLFunction('UpdateUser', parameter, 'mutation', false);

    const users = await Auth.currentAuthenticatedUser({ bypassCache: true });
    this.props.auth.setUser(users);
    this.hideLoader();
    this.props.history.push('/Explore');
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        isImageUploaded: true,
        profileImage: file,
        profilePic: reader.result,
        profilePicLocal: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }

  showLoader = () => {
    document.getElementsByClassName('loader-overlay')[0].classList.remove('d-none');
  };

  hideLoader = () => {
    document.getElementsByClassName('loader-overlay')[0].classList.add('d-none');
  };

  handleStripeAuthorize = async () => {
    this.showLoader();
    if (this.state.sellerId) {
      try {
        const sellerAccount = { seller_account_id: this.state.sellerId };
        let sellerAccountResult = await axios.post(
          `${config.api.invokeUrl}connect/login`,
          sellerAccount
        );
        if (
          sellerAccountResult.status === 200 &&
          sellerAccountResult.data.data.url
        ) {
          let stripeLoginLink = sellerAccountResult.data.data.url;
          window.open(stripeLoginLink);
        }
      } catch (error) {
        console.error(error);
      }
    }
    this.hideLoader();
  };

  render() {
    const {
      amount,
      subAmtError,
      promotionAmount,
      promotionMonth,
      profilePic,
      displayName,
      nameError,
      preferred_username,
      usernameError,
      spaceError,
      dob,
      website,
      websiteError,
      email,
      description,
      cancelBtnLabel,
      flatProductMonth,
      stripeAccountStatus,
    } = this.state;
    let currentUser = this.props.auth.user;

    let stripeAccountError;
    let stripeAccount = '';
    if (
      (currentUser.attributes['custom:seller_check'] === 'true' ||
        currentUser.attributes['custom:seller_check'] === true) &&
      stripeAccountStatus === 'inactive'
    ) {
      stripeAccount = 'disabled';
      stripeAccountError = (
        <div className="alert alert-danger" role="alert">
          Your Stripe account is not registered properly. Please update your account
          by
          <strong className="click-here-btn" onClick={this.handleStripeAuthorize}>
            {' '}
            Clicking Here
          </strong>
          . Until then, you will not be able to set the subscription plan.
        </div>
      );
    }

    let subscriptionFields;

    if (
      currentUser.attributes['custom:seller_check'] === 'true' ||
      currentUser.attributes['custom:seller_check'] === true ||
      this.state.sellerId
    ) {
      subscriptionFields = (
        <div>
          <div className="row">
            <div className="col-md-12">
              <label>Set Subscription Price</label>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Price"
                    required
                    value={amount}
                    onChange={this.onAmountChange}
                    id="amount"
                    name="amount"
                    disabled={stripeAccount}
                  />
                </div>
                <div style={{ fontSize: 12, color: 'red' }}>{subAmtError}</div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <select
                  className="form-control"
                  value={flatProductMonth}
                  selected={flatProductMonth}
                  onChange={this.onFlatProductMonthChange}
                >
                  <option value="1">per month</option>
                  <option value="12">Per Year</option>
                </select>
                <div style={{ fontSize: 12, color: 'red' }}>{subAmtError}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label>Your Active Promotions</label>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Price"
                    required
                    value={promotionAmount}
                    onChange={this.onPromotionAmtChange}
                    id="promotionAmount"
                    name="promotionAmount"
                    disabled={stripeAccount}
                  />
                </div>
                <div style={{ fontSize: 12, color: 'red' }}>{subAmtError}</div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="form-group">
                <div className="d-flex align-items-center">
                  <span className="mr-3">per</span>
                  <input
                    type="text"
                    className="form-control mr-3 unit-input"
                    required
                    id="promotionMonth"
                    name="promotionMonth"
                    value={promotionMonth}
                    onChange={this.onPromotionMonthChange}
                    disabled={stripeAccount}
                  />
                  <div className="col-md-6 p-0 mr-3">
                    <select className="form-control">
                      <option>month(s)</option>
                      {/* <option>Per Year</option> */}
                    </select>
                    <div style={{ fontSize: 12, color: 'red' }}>{subAmtError}</div>
                  </div>
                  <button className="delete-link" hidden>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="main-wapper">
          <Sidebar {...this.props} />
          <div className="content-wrapper h-100">
            <Header {...this.props} />

            <div className="popup-box">
              <div className="edit-profile content-box">
                <div className="user-profile-pic">
                  <div className="position-relative">
                    <div className="user-img">
                      <div className="user-img-inner">
                        <img src={profilePic} alt="" />
                      </div>
                      <a href="#" className="btn-change-pic">
                        <i className="fas fa-pencil-alt"></i>
                        <input
                          type="file"
                          id="profilePic"
                          className="form-control"
                          onChange={(e) => this._handleImageChange(e)}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="form text-left">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Display Name</label>
                          <input
                            type="text"
                            required
                            id="displayName"
                            name="displayName"
                            className="form-control"
                            value={displayName}
                            onChange={this.onInputChange}
                          />
                          <div style={{ fontSize: 12, color: 'red' }}>
                            {nameError}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Username<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            id="preferred_username"
                            className="form-control"
                            name="preferred_username"
                            value={preferred_username}
                            onChange={this.onInputChange}
                          />
                          <div style={{ fontSize: 12, color: 'red' }}>
                            {usernameError}
                            {spaceError}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Date of Birth</label>
                          <div className="position-relative">
                            <DatePicker
                              id="dob"
                              className="form-control pr-5"
                              onChange={(date) => {
                                this.setState({ dob: date.toDateString() });
                              }}
                              maxDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() - 20
                                  )
                                )
                              }
                              value={dob}
                              showYearDropdown
                              scrollableMonthYearDropdown
                              dateFormat="dd/MM/yyyy"
                            />
                            <i className="fas fa-calendar-alt icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Website</label>
                          <input
                            type="text"
                            required
                            id="website"
                            name="website"
                            className="form-control"
                            value={website}
                            onChange={this.onInputChange}
                          />
                          <div style={{ fontSize: 12, color: 'red' }}>
                            {websiteError}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            readOnly
                            disabled=""
                            type="text"
                            name="email"
                            className="form-control"
                            value={email}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            id="description"
                            className="form-control"
                            rows="3"
                            value={description}
                            onChange={this.onInputChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {stripeAccountError}
                    {subscriptionFields}
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <a href="/Explore" className="btn btn-outline">
                    {cancelBtnLabel}
                  </a>
                  <button
                    onClick={this.handleSubmit}
                    id="saveBtn"
                    className="btn btn-purple"
                  >
                    Save
                  </button>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <div
          className="modal fade popup-box change-password"
          id="changePassword"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <a href="#;" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </a>
              <div className="modal-body">
                <div className="popup-content">
                  <div>
                    <h4>Change Password</h4>
                    <div className="form text-left">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Email Address</label>
                            <input type="email" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Old Password</label>
                            <input type="password" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>New Password</label>
                            <input type="password" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Confirm Password</label>
                            <input type="password" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <a href="#" className="btn btn-purple">
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
