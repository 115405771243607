import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assests/css/style.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import graphQLFunction from './../graphql/graphQLFunction';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import config from './../config';
const PER_PAGE = 100;

function makeAndHandleRequest(query, nextToken) {
  let parameter = query.toLowerCase();
  return graphQLFunction(
    'SearchUserWithPagination',
    parameter,
    'query',
    nextToken
  ).then((resp) => {
    let data = resp.data.listHwm_users.items;
    let nextToken = resp.data.listHwm_users.nextToken;
    const options = data.map((i, number) => ({
      avatar_url: loadImage(
        `${config.api.s3BucketURL}/${i.user_id}/${i.user_id}.jpg`
      ),
      id: i.user_id,
      name: i.username,
      displayName: i.display_name.substr(1),
      displayNameLS: i.display_name_ls,
    }));
    return { options, nextToken };
  });
}

let loadImage = function (variable) {
  console.log('variable', variable);

  var image = new Image();
  var url_image = variable;
  image.src = url_image;
  if (image.width == 0) {
    console.log('no', variable);
    return `${config.api.s3BucketURL}/unknown.jpg`;
  } else {
    console.log('yes', variable);
    return variable;
  }
};

export default class SearchDropdown extends Component {
  state = {
    isLoading: false,
    options: [],
    query: '',
    nextToken: false,
  };
  _cache = {};

  render() {
    return (
      <AsyncTypeahead
        {...this.state}
        id="async-pagination-example"
        labelKey="displayNameLS"
        className="search-autocomplete"
        maxResults={PER_PAGE - 1}
        minLength={2}
        onInputChange={this._handleInputChange}
        onPaginate={this._handlePagination}
        onSearch={this._handleSearch}
        paginate
        placeholder="Search user"
        renderMenuItemChildren={(option) => (
          <div
            key={option.id}
            onClick={() => {
              this.redirectTo(option.name, option.displayName);
            }}
          >
            <img alt={option.name} src={option.avatar_url} className="search-user" />
            <h5>{option.displayName}</h5>
          </div>
        )}
        useCache={false}
      />
    );
  }
  redirectTo = (name, displayName) => {
    document.getElementsByClassName('rbt-input-main').value = displayName;
    window.location.assign('/Profile/' + name);
  };
  _handleInputChange = (query) => {
    this.setState({ query });
  };

  _handlePagination = (e, shownResults) => {
    const { query } = this.state;
    const cachedQuery = this._cache[query];
    if (this.state.nextToken === null) {
      return;
    }

    this.setState({ isLoading: true });

    const page = cachedQuery.page + 1;

    makeAndHandleRequest(query, this.state.nextToken).then((resp) => {
      const options = cachedQuery.options.concat(resp.options);
      this._cache[query] = { ...cachedQuery, options, page };
      this.setState({
        isLoading: false,
        options,
        nextToken: resp.nextToken,
      });
    });
  };

  _handleSearch = (query) => {
    this.setState({
      nextToken: null,
      isLoading: true,
      options: [],
    });
    makeAndHandleRequest(query, this.state.nextToken).then((resp) => {
      this._cache[query] = { ...resp, page: 1 };
      this.setState({
        isLoading: false,
        options: resp.options,
        nextToken: resp.nextToken,
      });
    });
  };
}
