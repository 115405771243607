import React, { Component } from "react";
import "../assests/css/style.css";
import Sidebar from "./Sidebar";
import Footer from './Footer';
import Header from "./Header";

class Homefeed extends Component {
  constructor(props) {
    super(props);
    const isUserLoggedIn = this.checkIsUserLoggedIn();
    if (!isUserLoggedIn) {
        this.props.history.push("/Loginpage");
    }
  }

  checkIsUserLoggedIn = () => {
    return (
      this.props &&
      this.props.auth &&
      this.props.auth.isAuthenticated &&
      this.props.auth.user
    );
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  removeBodyClass = () => {
    document.body.classList.remove("bodyStyle");
  };

  render() {
    this.removeBodyClass();

    const isUserLoggedIn = this.checkIsUserLoggedIn();
    if (!isUserLoggedIn) {
      return null;
    }
    return (
      <div>

        <div className="main-wapper">
        <Sidebar {...this.props} />
          <div className="content-wrapper h-100">
            <Header {...this.props}/>

            <div className="feed-section">
              <div className="sec-head mb-3 d-flex align-items-center justify-content-between">
                <h3 className="section-heading">Home</h3>
              </div>
              <div className="feeds">
                <div className="left-content">
                  <article className="feed-box">
                    <header className="feed-header d-flex align-items-center justify-content-between">
                      <div className="subscriber-info d-flex align-items-center">
                        <div className="subscriber online">
                          <div className="subscriber-img">
                            <img
                              src={require("../assests/images/user.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="subscriber-details">
                          <h5>Chef Mia</h5>
                          <p>@theechefmia</p>
                        </div>
                      </div>
                      <div className="subscriber-feed-info d-flex align-items-center">
                        <div className="d-flex flex-column align-items-end">
                          <div className="post-time">6 hours ago</div>
                          <div className="post-price">$5.50</div>
                        </div>
                        <div className="more-info ml-4">
                          <a href="#" className="btn-more-info">
                            <i className="fas fa-ellipsis-h"></i>
                          </a>
                        </div>
                      </div>
                    </header>
                    <figure className="post-img locked">
                      <a href="#" className="btn-lock">
                        <i className="icon-lock"></i>
                      </a>
                      <img
                        src={require("../assests/images/images/Dreamy-child-girl-ipad-air-wallpaper-ilikewallpaper_com_1024x1024-progressive.jpeg")}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <div>
                      <div className="post-info">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="like-and-comments d-flex align-items-center">
                            <div className="likes mr-4">
                              <span className="liked">
                                <i className="icon-heart"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="comments mr-4">
                              <span>
                                <i className="icon-message"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="tip">
                              <span>
                                <i className="icon-tip"></i>
                                <span>Tip</span>
                              </span>
                            </div>
                          </div>
                          <div className="bookmark">
                            <a href="#" className="link-bookmark">
                              <i className="icon-bookmark"></i>
                            </a>
                          </div>
                        </div>
                        <div className="post-text">
                          <p className="mb-3">
                            @theechefmia Cooking up a special new dish for you
                            today. It includes my favorite ingredients! Can you
                            guess?
                          </p>
                        </div>
                      </div>
                      <div className="message-box">
                        <textarea placeholder="Add Comment" rows="2"></textarea>
                        <button className="btn btn-purple btn-post">
                          Post
                        </button>
                      </div>
                    </div>
                  </article>
                  <article className="feed-box">
                    <header className="feed-header d-flex align-items-center justify-content-between">
                      <div className="subscriber-info d-flex align-items-center">
                        <div className="subscriber online">
                          <div className="subscriber-img">
                            <img
                              src={require("../assests/images/user.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="subscriber-details">
                          <h5>Chef Mia</h5>
                          <p>@theechefmia</p>
                        </div>
                      </div>
                      <div className="subscriber-feed-info d-flex align-items-center">
                        <div className="d-flex flex-column align-items-end">
                          <div className="post-time">6 hours ago</div>
                        </div>
                        <div className="more-info ml-4">
                          <a href="#" className="btn-more-info">
                            <i className="fas fa-ellipsis-h"></i>
                          </a>
                        </div>
                      </div>
                    </header>
                    <figure className="post-img">
                      <a href="#" className="btn-lock">
                        <i className="icon-lock"></i>
                      </a>
                      <img
                        src={require("../assests/images/images/11141018_ml-progressive.jpeg")}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <div>
                      <div className="post-info">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="like-and-comments d-flex align-items-center">
                            <div className="likes mr-4">
                              <span>
                                <i className="icon-heart"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="comments mr-4">
                              <span>
                                <i className="icon-message"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="tip">
                              <span>
                                <i className="icon-tip"></i>
                                <span>Tip</span>
                              </span>
                            </div>
                          </div>
                          <div className="bookmark">
                            <a href="#" className="link-bookmark">
                              <i className="icon-bookmark"></i>
                            </a>
                          </div>
                        </div>
                        <div className="post-text">
                          <p className="mb-3">
                            @theechefmia Cooking up a special new dish for you
                            today. It includes my favorite ingredients! Can you
                            guess?
                          </p>
                        </div>
                      </div>
                      <div className="message-box">
                        <textarea placeholder="Add Comment" rows="2"></textarea>
                        <button className="btn btn-purple btn-post">
                          Post
                        </button>
                      </div>
                    </div>
                  </article>
                  <article className="feed-box">
                    <header className="feed-header d-flex align-items-center justify-content-between">
                      <div className="subscriber-info d-flex align-items-center">
                        <div className="subscriber online">
                          <div className="subscriber-img">
                            <img
                              src={require("../assests/images/user.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="subscriber-details">
                          <h5>Chef Mia</h5>
                          <p>@theechefmia</p>
                        </div>
                      </div>
                      <div className="subscriber-feed-info d-flex align-items-center">
                        <div className="d-flex flex-column align-items-end">
                          <div className="post-time">6 hours ago</div>
                        </div>
                        <div className="more-info ml-4">
                          <a href="#" className="btn-more-info">
                            <i className="fas fa-ellipsis-h"></i>
                          </a>
                        </div>
                      </div>
                    </header>
                    <figure className="post-img">
                      <img
                        src={require("../assests/images/images/unnamed-progressive.jpeg")}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <div>
                      <div className="post-info">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="like-and-comments d-flex align-items-center">
                            <div className="likes mr-4">
                              <span>
                                <i className="icon-heart"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="comments mr-4">
                              <span>
                                <i className="icon-message"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="tip">
                              <span>
                                <i className="icon-tip"></i>
                                <span>Tip</span>
                              </span>
                            </div>
                          </div>
                          <div className="bookmark">
                            <a href="#" className="link-bookmark">
                              <i className="icon-bookmark"></i>
                            </a>
                          </div>
                        </div>
                        <div className="post-text">
                          <p className="mb-3">
                            @theechefmia Cooking up a special new dish for you
                            today. It includes my favorite ingredients! Can you
                            guess?
                          </p>
                        </div>
                      </div>
                      <div className="message-box">
                        <textarea placeholder="Add Comment" rows="2"></textarea>
                        <button className="btn btn-purple btn-post">
                          Post
                        </button>
                      </div>
                    </div>
                  </article>
                  <article className="feed-box">
                    <header className="feed-header d-flex align-items-center justify-content-between">
                      <div className="subscriber-info d-flex align-items-center">
                        <div className="subscriber online">
                          <div className="subscriber-img">
                            <img
                              src={require("../assests/images/user.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="subscriber-details">
                          <h5>Chef Mia</h5>
                          <p>@theechefmia</p>
                        </div>
                      </div>
                      <div className="subscriber-feed-info d-flex align-items-center">
                        <div className="d-flex flex-column align-items-end">
                          <div className="post-time">6 hours ago</div>
                        </div>
                        <div className="more-info ml-4">
                          <a href="#" className="btn-more-info">
                            <i className="fas fa-ellipsis-h"></i>
                          </a>
                        </div>
                      </div>
                    </header>
                    <figure className="post-img">
                      <img
                        src={require("../assests/images/images/timothy-paul-smith-256424-1200x800-progressive.jpeg")}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <div>
                      <div className="post-info">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="like-and-comments d-flex align-items-center">
                            <div className="likes mr-4">
                              <span>
                                <i className="icon-heart"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="comments mr-4">
                              <span>
                                <i className="icon-message"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="tip">
                              <span>
                                <i className="icon-tip"></i>
                                <span>Tip</span>
                              </span>
                            </div>
                          </div>
                          <div className="bookmark">
                            <a href="#" className="link-bookmark">
                              <i className="icon-bookmark"></i>
                            </a>
                          </div>
                        </div>
                        <div className="post-text">
                          <p className="mb-3">
                            @theechefmia Cooking up a special new dish for you
                            today. It includes my favorite ingredients! Can you
                            guess?
                          </p>
                        </div>
                      </div>
                      <div className="message-box">
                        <textarea placeholder="Add Comment" rows="2"></textarea>
                        <button className="btn btn-purple btn-post">
                          Post
                        </button>
                      </div>
                    </div>
                  </article>
                  <article className="feed-box">
                    <header className="feed-header d-flex align-items-center justify-content-between">
                      <div className="subscriber-info d-flex align-items-center">
                        <div className="subscriber online">
                          <div className="subscriber-img">
                            <img
                              src={require("../assests/images/user.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="subscriber-details">
                          <h5>Chef Mia</h5>
                          <p>@theechefmia</p>
                        </div>
                      </div>
                      <div className="subscriber-feed-info d-flex align-items-center">
                        <div className="d-flex flex-column align-items-end">
                          <div className="post-time">6 hours ago</div>
                        </div>
                        <div className="more-info ml-4">
                          <a href="#" className="btn-more-info">
                            <i className="fas fa-ellipsis-h"></i>
                          </a>
                        </div>
                      </div>
                    </header>
                    <figure className="post-img">
                      <img
                        src={require("../assests/images/images/NATALIE-DORMERs-picture-progressive.jpeg")}
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <div>
                      <div className="post-info">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="like-and-comments d-flex align-items-center">
                            <div className="likes mr-4">
                              <span>
                                <i className="icon-heart"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="comments mr-4">
                              <span>
                                <i className="icon-message"></i>
                                <span>0</span>
                              </span>
                            </div>
                            <div className="tip">
                              <span>
                                <i className="icon-tip"></i>
                                <span>Tip</span>
                              </span>
                            </div>
                          </div>
                          <div className="bookmark">
                            <a href="#" className="link-bookmark">
                              <i className="icon-bookmark"></i>
                            </a>
                          </div>
                        </div>
                        <div className="post-text">
                          <p className="mb-3">
                            @theechefmia Cooking up a special new dish for you
                            today. It includes my favorite ingredients! Can you
                            guess?
                          </p>
                        </div>
                      </div>
                      <div className="message-box">
                        <textarea placeholder="Add Comment" rows="2"></textarea>
                        <button className="btn btn-purple btn-post">
                          Post
                        </button>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="right-content">
                  <div className="trending-profiles">
                    <header>
                      <h5>Profiles you Follow</h5>
                      <a href="#" className="link-seeAll">
                        See All
                      </a>
                    </header>
                    <ul className="subscribed-users">
                      <li>
                        <div className="subscriber-info d-flex align-items-center">
                          <div className="subscriber online">
                            <div className="subscriber-img">
                              <img
                                src={require("../assests/images/user-2.png")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="subscriber-details">
                            <h5>Chef Mia</h5>
                            <p>@theechefmia</p>
                          </div>
                        </div>
                        <a href="#" className="link-subscribe">
                          Unsubscribe
                        </a>
                      </li>
                      <li>
                        <div className="subscriber-info d-flex align-items-center">
                          <div className="subscriber online">
                            <div className="subscriber-img">
                              <img
                                src={require("../assests/images/user.png")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="subscriber-details">
                            <h5>Chef Mia</h5>
                            <p>@theechefmia</p>
                          </div>
                        </div>
                        <a href="#" className="link-subscribe">
                          Unsubscribe
                        </a>
                      </li>
                      <li>
                        <div className="subscriber-info d-flex align-items-center">
                          <div className="subscriber online">
                            <div className="subscriber-img">
                              <img
                                src={require("../assests/images/user-2.png")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="subscriber-details">
                            <h5>Chef Mia</h5>
                            <p>@theechefmia</p>
                          </div>
                        </div>
                        <a href="#" className="link-subscribe">
                          Unsubscribe
                        </a>
                      </li>
                      <li>
                        <div className="subscriber-info d-flex align-items-center">
                          <div className="subscriber online">
                            <div className="subscriber-img">
                              <img
                                src={require("../assests/images/user-3.png")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="subscriber-details">
                            <h5>Chef Mia</h5>
                            <p>@theechefmia</p>
                          </div>
                        </div>
                        <a href="#" className="link-subscribe">
                          Unsubscribe
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="trending-profiles">
                    <header>
                      <h5>Trending Profiles</h5>
                      <a href="#" className="link-seeAll">
                        See All
                      </a>
                    </header>
                    <ul className="subscribed-users">
                      <li>
                        <div className="subscriber-info d-flex align-items-center">
                          <div className="subscriber online">
                            <div className="subscriber-img">
                              <img
                                src={require("../assests/images/user-4.png")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="subscriber-details">
                            <h5>Chef Mia</h5>
                            <p>@theechefmia</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="subscriber-info d-flex align-items-center">
                          <div className="subscriber online">
                            <div className="subscriber-img">
                              <img
                                src={require("../assests/images/user.png")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="subscriber-details">
                            <h5>Chef Mia</h5>
                            <p>@theechefmia</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="subscriber-info d-flex align-items-center">
                          <div className="subscriber online">
                            <div className="subscriber-img">
                              <img
                                src={require("../assests/images/user.png")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="subscriber-details">
                            <h5>Chef Mia</h5>
                            <p>@theechefmia</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homefeed;
