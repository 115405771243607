import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assests/css/style.css";
import Masonry from "react-masonry-component";
import Footer from "./Footer.js";
import Sidebar from "./Sidebar";
import Header from "./Header";

class Explore extends Component {
  constructor(props) {
    super(props);
    const isUserLoggedIn = this.checkIsUserLoggedIn();
    if (!isUserLoggedIn) {
        this.props.history.push("/Loginpage");
    }
  }

  checkIsUserLoggedIn = () => {
    return (
      this.props &&
      this.props.auth &&
      this.props.auth.isAuthenticated &&
      this.props.auth.user
    );
  };

  removeBodyClass = () => {
    document.body.classList.remove("bodyStyle");
  };

  render() {
    this.removeBodyClass();
    return (
      <div>
        <div className="main-wapper">
          <Sidebar {...this.props} />
          <div className="content-wrapper h-100">
            <Header {...this.props}/>

            <div className="explore-section">
              <div className="sec-head mb-3 d-flex align-items-center justify-content-between">
                <h3 className="section-heading">Explore</h3>
                <div className="action-btn">
                  <a href="#" className="btn-link active">
                    Latest
                  </a>
                  <a href="#" className="btn-link">
                    Popular
                  </a>
                </div>
              </div>
              {/* <Masonry>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/unnamed-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Jenny Joy</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/92027704_243123707072773_8975912689650522407_n-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Lauren Loves</h5>
                    <p className="caption-text">Touring my new murals! Join!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/11141018_ml-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/belaruswomen-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/Dreamy-child-girl-ipad-air-wallpaper-ilikewallpaper_com_1024x1024-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/editors_choice_13x1300cb36-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>

                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/NATALIE-DORMERs-picture-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/photo-1481824429379-07aa5e5b0739-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/photo-1536366764833-579c4e583927-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/11141018_ml-progressive.jpeg")}
                      alt=""
                    />
                  </figure>

                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/gopro-photography-tips-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>

                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/belaruswomen-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Jenny Joy</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>

                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/belaruswomen-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/unnamed-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/92027704_243123707072773_8975912689650522407_n-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Jenny Joy</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/11141018_ml-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/belaruswomen-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/Dreamy-child-girl-ipad-air-wallpaper-ilikewallpaper_com_1024x1024-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/editors_choice_13x1300cb36-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>

                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/NATALIE-DORMERs-picture-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Soul Ma</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/photo-1481824429379-07aa5e5b0739-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/photo-1536366764833-579c4e583927-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Jenny Joy</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/11141018_ml-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/gopro-photography-tips-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Jenny Joy</h5>
                    <p className="caption-text">Visiting my favorite spot!</p>
                  </div>
                </div>

                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/belaruswomen-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>

                <div className="grid-item">
                  <figure className="img-box">
                    <img
                      src={require("../assests/images/images/belaruswomen-progressive.jpeg")}
                      alt=""
                    />
                  </figure>
                  <div className="image-caption">
                    <h5 className="caption-heading">Chef Mike </h5>
                    <p className="caption-text">
                      New custom recipe! You ready?
                    </p>
                  </div>
                </div>
              </Masonry> */}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Explore;
