function GetLoggedInUserDetail(auth){
  let cognitoUser = auth;
  if (auth.user){
    cognitoUser = auth.user;
  }
  if (auth.user && auth.user.attributes) {
    cognitoUser = auth.user.attributes;
  }
  if (auth.user && auth.user.signInUserSession.idToken.payload) {
    if (auth.user.attributes){
      cognitoUser = auth.user.attributes;
    } else {
      cognitoUser = auth.user.signInUserSession.idToken.payload;
    }
  }
  return cognitoUser;
}

export default GetLoggedInUserDetail;