import React, { Component } from "react";
import "../assests/css/style.css";
import emailGraphics from "../assests/images/email-graphics.svg";
export default class WelcomePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <div className="fullpage-popup welcome-popup">
        <div className="popup-content white-radius-box">
            <h3 className="text">Welcome</h3>
            <div className="graphics">
                <img src={emailGraphics} alt="" />
            </div>
            <h5 className="text-center">You Have Successfully Registered</h5>
            <p className="text-center">We've sent you an email. Please click on the confirmation link to verify your
                account.</p>
            <a href="/Loginpage" className="btn btn-purple w-100">Log In</a>
        </div>
    </div>
    );
  }
}
