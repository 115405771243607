import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assests/css/style.css';
import { validateLogin } from './utility/FormValidation';
import { Auth } from 'aws-amplify';
import landingLogo from '../assests/images/logo.svg';
import FormErrors from './FormErrors';
import Footer from './Footer';

class Loginpage extends Component {
  state = {
    username: '',
    password: '',
    disabled: true,
    errors: {
      errMessage: null,
    },
  };

  showLoader = () => {
    document.getElementsByClassName('loader-overlay')[0].classList.remove('d-none');
  };

  hideLoader = () => {
    document.getElementsByClassName('loader-overlay')[0].classList.add('d-none');
  };

  showButton = () => {
    document.getElementById('loginButton').classList.remove('disabled');
    document.getElementById('loginButton').classList.add('btn-purple');
  };

  hideButton = () => {
    document.getElementById('loginButton').classList.add('disabled');
    document.getElementById('loginButton').classList.remove('btn-purple');
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        errMessage: null,
      },
    });
  };

  callAwsSignIn = async (event) => {
    try {
      const user = await Auth.signIn(this.state.username, this.state.password);
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);
      this.hideLoader();
      this.props.history.push('/welcome');
    } catch (error) {
      console.log('cognitoError', error);

      this.hideLoader();
      if (
        error.message == 'User is not confirmed.' ||
        error.code == 'UserNotConfirmedException'
      ) {
        this.setState({
          errors: {
            errMessage: 'Please verify your email address to proceed',
          },
        });
      } else {
        this.setState({
          errors: {
            errMessage: error.message,
          },
        });
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.clearErrorState();
    const error = validateLogin();
    if (error === '') {
      this.showLoader();
      await this.callAwsSignIn();
    }
  };

  addBodyClass = () => {
    document.body.classList.add('bodyStyle');
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove('is-danger');

    const error = validateLogin();
    if (error === '') {
      this.setState({ disabled: false });
      this.showButton();
    } else {
      this.setState({ disabled: true });
      this.hideButton();
    }
  };

  render() {
    this.addBodyClass();
    return (
      <div className="wrapper section auth">
        <div className="login d-flex flex-column flex-grow-1">
          <div className="flex-grow-1 d-flex justify-content-center align-items-sm-center">
            <div className="white-radius-box box">
              <a href="/" className="login-logo">
                <img src={landingLogo} alt="" />
              </a>
              <h5 className="box-heading text-center">Log In</h5>
              <FormErrors formerrors={this.state.errors} />

              <div className="d-flex align-items-center justify-content-between social-login">
                <button
                  className="btn btn-outline btn-facebook"
                  onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
                  onSubmit={this.handleSubmit}
                >
                  <i className="fab fa-facebook-f fa-lg mr-2"></i>Continue
                </button>
                <button
                  className="btn btn-outline btn-google"
                  onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
                  onSubmit={this.handleSubmit}
                >
                  <i className="fab fa-google fa-lg mr-2"></i>Continue
                </button>
              </div>

              <form onSubmit={this.handleSubmit} className="w-100">
                <div className="row login-fields field">
                  <div className="col-12">
                    <div className="form-group position-relative control">
                      <input
                        name="username"
                        id="username"
                        className="form-control input required"
                        placeholder="Email Address or Username"
                        value={this.state.email}
                        onChange={this.onInputChange}
                        onBlur={() => {
                          const error = validateLogin();
                          if (error === '' && this.state.password !== '') {
                            this.setState({ disabled: false });
                            this.showButton();
                          } else {
                            this.setState({ disabled: true });
                            this.hideButton();
                          }
                        }}
                      />
                      <div className="errMessage text-left"></div>
                    </div>
                  </div>
                  <div className="col-12 field">
                    <div className="mb-4 form-group control position-relative">
                      <input
                        type="password"
                        id="password"
                        className="form-control input required"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onInputChange}
                        onBlur={() => {
                          const error = validateLogin();
                          if (error === '' && this.state.email !== '') {
                            this.setState({ disabled: false });
                            this.showButton();
                          } else {
                            this.setState({ disabled: true });
                            this.hideButton();
                          }
                        }}
                      />
                      <div className="errMessage text-left"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group text-center">
                      <a href="/forgotpassword" className="link-forgot-password">
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-5 field">
                  <button
                    disabled={this.state.disabled}
                    id="loginButton"
                    className="btn disabled w-100 control"
                  >
                    Log In
                  </button>
                </div>
              </form>
              <p className="already-member text-center mt-4 mb-0">
                Not a member?
                <a href="Signuppage" className="ml-1">
                  Sign Up
                </a>
              </p>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default Loginpage;
