import { API, graphqlOperation } from 'aws-amplify';

async function graphQLFunction(baseQuery, parameter, type, nextToken = false) {
  let query = '';
  switch (baseQuery) {
    // user queries and mutations
    case 'CreateUser': {
      query = `
        mutation CreateHwmUser($input: CreateHwm_usersInput!) {
          createHwm_users(input:$input) {
            user_id
            email
            caption
            first_name
            last_name
            username
            display_name
            display_name_ls
            flat_subscription_amount
            promotion_amount
            promotion_month
          }
        }`;
      break;
    }
    case 'UpdateUser': {
      query = `
        mutation UpdateHwmUser($input: UpdateHwm_usersInput!) {
          updateHwm_users(input: $input) {
            user_id
            email
            first_name
            last_name
            display_name
            display_name_ls
            caption
            username
            flat_subscription_amount
            promotion_amount
            promotion_month
            account_id
            flat_product_month
          }
        }`;
      break;
    }
    case 'UsernameSearch': {
      query = `
        query listSearch {
          listHwm_users(limit:200, filter: {
            username: {
              contains: "${parameter}"
            }
          }) {
            items {
              user_id
              account_id
              email
              username
              first_name
              display_name
              display_name_ls
              caption
              username
              flat_subscription_amount
              promotion_amount
              promotion_month
              account_id
              flat_product_month
            }
          }
        }
      `;
      break;
    }
    case 'SearchUserWithPagination': {
      if (nextToken) {
        query = `
        query listSearch {
          listHwm_users(limit:100, nextToken:"${nextToken}", filter: {
            display_name_ls: {
              contains: "${parameter}"
            }
          }) {
            items {
              user_id
              email
              username
              first_name
              display_name
              display_name_ls
            }
            nextToken
          }
        }`;
      } else {
        query = `
        query listSearch {
          listHwm_users(limit:100, nextToken:"", filter: {
            display_name_ls: {
              contains: "${parameter}"
            }
          }) {
            items {
              user_id
              email
              username
              first_name
              display_name
              display_name_ls
            }
            nextToken
          }
        }`;
      }
      break;
    }
    // media queries and mutations
    case 'UpdateMedia': {
      query = `
      mutation UpdateHwmMedia($input: UpdateHwmMediaInput!){
        updateHwmMedia(input:"${parameter}"){
          user_id
          media_id
          media_description
        }
      }`;
      break;
    }

    case 'ListMedia': {
      if (nextToken) {
        query = `
          query listSearchmedia {
            listHwm_medias(limit:200, nextToken:"${nextToken}", filter: ${parameter}) {
              items {
                user_id
                media_id
                media_description
                media_type
                media_url
                media_title
                media_path
                created_date
                media_price
                security_attribute
                price_id
                product_id
                media_state
                media_duration
              }
              nextToken
            }
          }
          `;
      } else {
        query = `
          query listSearchmedia {
            listHwm_medias(limit:100, nextToken:"", filter: ${parameter}) {
              items {
                user_id
                media_id
                media_description
                media_type
                media_url
                media_title
                media_path
                created_date
                media_price
                security_attribute
                price_id
                product_id
                media_state
                media_duration
              }
              nextToken
            }
          }
          `;
      }
      break;
    }

    case 'PreSignedUrlGet': {
      query = `
          query preSignedUrlPost {
            getSignedUrl(user_id:"${parameter.user_id}",media_id: "${parameter.media_id}",media_name:"${parameter.media_title}")
              {
                statusCode
                body
              }
            }`;
      break;
    }
    case 'PreSignedUrlPost': {
      query = `
          query preSignedUrlPost {
              preSignedUrl(user_id:"${parameter.user_id}",media_name:"${parameter.media_name}")
              {
                statusCode
                body
              }
              }
              `;
      break;
    }
    case 'GetSignedUrlImage': {
      query = `
            query getSignedUrlImage{
              getSignedUrlImage(user_id:"${parameter.user_id}",media_id: "${parameter.media_id}",media_name:"${parameter.media_title}")
                {
                  statusCode
                  body
                }
              }`;
      break;
    }

    case 'PreSignedUrlImage': {
      query = `
          query preSignedUrlImage{
            preSignedUrlImage(user_id:"${parameter.user_id}",media_name:"${parameter.media_name}")
            {
                statusCode
                body
            }
          }
              `;
      break;
    }
    case 'GetSignedUrlVideo': {
      query = `
            query getSignedUrlVideo{
              getSignedUrlVideo(user_id:"${parameter.user_id}",path: "${parameter.path}")
                {
                  statusCode
                  body
                }
              }`;
      break;
    }

    case 'PreSignedUrlVideo': {
      query = `
          query preSignedUrlVideo{
            preSignedUrlVideo(user_id:"${parameter.user_id}",media_name:"${parameter.media_name}")
            {
                statusCode
                body
          }
          }
              `;
      break;
    }
    case 'GetSignedUrlPost': {
      query = `
            query getSignedUrl {
              getSignedUrl(user_id:"${parameter.user_id}",media_id: "${parameter.media_id}",media_name:"${parameter.media_name}")
                {
                  statusCode
                  body
                }
              }`;
      break;
    }

    case 'CreateHwmMedia': {
      query = `
        mutation CreateHwmMedia($input: CreateHwm_mediaInput!){
          createHwm_media(input:$input) {
              media_id
              user_id
              media_description
              media_url
              media_type
              media_title
              media_path
              media_price
              created_date
              security_attribute
              price_id
              product_id
              media_state
              media_duration
            }
        }`;
      break;
    }
    ///following and followers mutations and queries
    case 'CreateProduct': {
      query = `
        mutation createFollowers{
          createLookupsDev(input:${parameter}){
          object_type
          object_id
          pricing_id
          product_id
          account_id
          }
          }`;
      break;
    }
    case 'GetFollowing': {
      query = `
          query getfollowing {
            getLookupsDev(${parameter}){
                object_type
                object_id
                value
                pricing_id
                product_id
                customer_id
            }
        }
          `;
      break;
    }

    case 'GetFollower': {
      query = `
        query getfollowers {
          getLookupsDev(${parameter}){
              object_type
              object_id
              value
          }
        }
            `;
      break;
    }

    case 'Follow': {
      query = `
              mutation hwmfollowing{
                updateLookupsDev(input:${parameter}) {
                  entity_id
                  updated_timestamp
                  value
                }
              }
              `;
      break;
    }

    case 'UnFollow': {
      query = `
              mutation hwmUnfollowing{
                deleteLookupsDev(input:${parameter}) {
                  entity_id
                  updated_timestamp
                  value
                }
              }
              `;
      break;
    }
    ////
    default:
      break;
  }

  try {
    if (type === 'mutation') {
      return await API.graphql(graphqlOperation(query, { input: parameter }));
    }
    return await API.graphql(graphqlOperation(query));
  } catch (err) {
    console.log('error in calling ...', type, baseQuery, err);
    return err;
  }
}

export default graphQLFunction;
