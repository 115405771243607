import React, { Component } from "react";
import landingLogo from "../assests/images/logo.svg";
import Footer from './Footer';

class ChangePasswordConfirmation extends Component {

  render() {
    return (
      <section className="section auth">
             <div className="login d-flex flex-column flex-grow-1">
            <div className="flex-grow-1 d-flex justify-content-center align-items-sm-center">
              <div className="white-radius-box box d-flex flex-column">
                <a href="/" className="login-logo">
                  <img src={landingLogo} alt="" />
                </a>
                <h5 className="box-heading text-center">
          <p>Your password has been successfully updated!</p>
                   </h5>
                   <p className="already-member text-center mt-4 mb-0">
                   <a href='/Loginpage' className="text-decoration-none">
                    Login
                  </a>
              </p>
        </div>
        </div>
        <Footer />
        </div>
      </section>
    );
  }
}

export default ChangePasswordConfirmation;